.Header {
  display: flex;
  justify-content: space-between;
}

.backHomeButtonContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.backHomeButtonContainer img {
  /* height: 15px; */
  margin: 0 1em 0 0;
}

.backHomeButtonContainer button {
  color: rgba(181 60 63);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}


.pageNav {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.pageNav * {
  font-size: 0.7rem;
}

.ButtonContainer>button {
  color: rgba(181 60 63);
}

.ButtonContainer {}

.PlatformContainer {
  padding-top: 30px;
}


.toggleGroup button {
  width: 50%;
  font-size: 18px;
  font-weight: bold;
}

.container {
  padding: 50px 0 0;
  /*
    display: flex;
    flex-direction: column;
    height: calc(100vh - 45px);
    */
}

.container h4 {
  font-size: 32px;

}

.header {
  display: grid;
  grid-template-columns: auto auto;
  padding: 16px 8px 16px;
}


.row input {
  border-radius: 25px;
}

.rowSpan {
  display: grid;
  grid-template-columns: 30px 30px 20px 180px;
  grid-gap: 8px;
  direction: rtl;
  align-items: center;
  margin-right: 30px;
}

.border_line {
  border-right: 2px solid;
  height: 100%;
  margin: 0 8px;
}


.row {
  white-space: nowrap;
  padding: 0 10px 0 0;
  display: flex;
}

.row input {
  height: 48px;
  width: 220px;
}

.RowBtn {
  margin-right: 8px;
}

.tableHeader {
  display: grid;
  grid-template-columns: 30px 2fr 1fr 30px 30px;
  grid-gap: 16px;
  justify-content: space-between;
  padding-bottom: 8px;
  font-size: 22px;
  align-items: center;
}

.tableHeaderItemCol1 {
  padding-left: 8px;
}


.cardViewContainer {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  justify-content: space-between;
}

.dialogButtonContainer {
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.dialogButtonContainer button:last-child {
  color: white;
  background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
  width: 50px;
  border-radius: 50px;
  height: 25px;
  font-size: 0.7em;
}

.dialogButtonContainer button:first-child {
  color: rgba(181 60 63);
  background: pink;
  width: 50px;
  border-radius: 50px;
  height: 25px;
  font-size: 0.7em;
}


.page {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.page_row {
  display: flex;
  justify-content: center;
  margin: 10px;
  align-items: center;
}


.page_item {
  cursor: pointer;
  margin: 0 10px;
}

.listViewHeader {
  width: 100%;
  transition: 0.3s;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr 7fr 7fr 7fr 10fr;
  justify-items: center;
  grid-gap: 8px;
}

button {
  cursor: pointer;
}