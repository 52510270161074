.header-chapter-name,
.header-book-name {
  width: 45vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 30px;
  text-align: center;
  opacity: 0.3;
}
.header-container,
.footer-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 30px;
}
.header-container {
  position: absolute;
  top: 3px;
}
.footer-container {
  position: absolute;
  bottom: 22px;
}

.background-page-left,
.background-page-right {
  max-width: 45vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  opacity: 0.3;
}
