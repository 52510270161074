.backHomeButtonContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .backHomeButtonContainer  img {
    /* height: 15px; */
    margin: 0 1em 0 0;
  }
  .backHomeButtonContainer  button {
    color: rgba(181 60 63);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }



.Uploadbatchview_container{
    padding: 8px 75px 0;
}

.content_style  {
    font-size: 30px;
    margin: 16px 30px 8px;
}


.backbtn{
    padding: 25px 35px 0;
}

.dropzone{
    margin: 30px;
}


.header{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 8px;
    background-color: #9acab6;
    color :#00292e;
}

.container{
    background-color: #FEFEFE;
    margin: 30px;
    border-radius: 8px;
    border: 1px solid rgb(248, 247, 247);
    word-break: break-all;
}

.filerow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 45%; */
    flex-wrap: wrap;
    margin: 20px;
}

.btnRow{
    display: flex;
    justify-content: flex-end;
    margin: 0 30px;
}

.btnRow button{
    margin: 0 5px;
    padding: 1.5%;
}

.content {
    display: grid;
    grid-template-columns: auto auto;
    margin: 20px 40px 20px;
    gap:1em;
}

.row{
    position: relative;
    display: grid;
}

.rowSpan {
    display: grid;
    grid-template-columns: auto;
    margin: 20px 40px 20px;
    gap:1em;
}

.RowBtn{
    margin-left: -45px;
}


.button_on_right{
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}

.button_on_right button{
    margin: 0 5px;
}

.tableListItemGridView {
    display: grid;
    grid-gap: 28px;
    grid-template-columns: 10% 40% 10% 10%;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    padding: 16px;
    align-items: center;
  }
  



  @media screen and (max-width: 768px) {
    
    .Uploadbatchview_container{
        padding: 8px 15px 0;
    }
    .btnRow button{
        margin: 0 5px;
        padding: 10px 25px;
    }

    .container{
    margin: 5px;
    }

    .content_style  {
        font-size: 30px;
        margin: 16px 0px 8px;
    }
  }