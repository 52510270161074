@import '/src/assets/color.css';

.homeInnerContainer{
    max-width: 1440px;
    margin: 0 auto;
	display: flex;
}

.bannerContainer{
  /*TODO banner css*/
  /* height: 350px; */
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 70%;
}

.mainBannerContainer{
    /* width: 70%; */
    /* height: 100%; */
    /* flex:5; */
	/* padding-right: 7px; */
	padding-right: 1em;
	height: 350px;
}

.mainBannerContainer>img{
	/*TODO banner*/
    object-fit: fill;
	object-fit: contain;
    width: 100%;
    height: 100%;
    /* max-height: 300px; */
	height: 350px;
}

.secondBannerContainer{
    height: 100%;
    /* flex:2; */
    /* margin-left: 10px; */
    /* width: 30%; */
	height: 350px;
}

.secondBannerContainer>img{
	/*TODO banner*/
    object-fit: fill;
	object-fit: contain;
    width: 100%;
    height: 100%;
    /* max-height: 300px; */
	height: 350px;
}

.homeContent{
    display:flex;
     flex-direction: column; 
    /*flex-wrap: wrap;*/
	flex: 1;
	width: 30%;
}
/* .homeContent2{
    display:flex;
    flex-direction: column;
} */

.recommendationContainer{
    flex:5
}

.sideNavbar{
    flex:2
}

.recommendationContainer{
    /* margin:2em  2em 1em 0em; */
    display: flex;
    flex-direction: column;
    width: 100%;
	padding: 1em 1em 0em 0em;
    /* width: 70%; */
}

/* .recommendationContainer2{
    margin:2em  0em 1em 0em;
    display: flex;
    flex-direction: column;
} */


.TreeMeunContainer{
    margin:2em 1em 0 0;
}


.TreeMeunContainer2{
	margin:0 1em 0 0;
}


.header{
    display:flex;
    justify-content:space-between;
    margin: 0 0 1em 0
}

.header>h3{
color :gray
}

.moreContainer > button{
    font-size:0.7rem;
    color:rgb(239	152	111	);
}




::-webkit-scrollbar {
    width: 7px;
    height: 0.5rem;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    /* background-color: var( --color-orange-add90); */
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    transition: all 0.2s;
    border-radius: 0.5rem;
    opacity: 0.5;
    background-color: var(--color-black-add90);
    background-color: #9f9e9e;
}



.banner_part {
	display: grid;
	/* grid-template-columns: auto; */
	grid-template-rows: auto auto auto;
	/* grid-template-areas:
		"banner1"
		"banner2"
		"banner3"; */

	gap: 4px 4px;
}


/* @media screen and (min-width: 700px) {
	.banner_part {
		grid-template-columns: 7fr 3fr;
		grid-template-rows: auto auto;
		grid-template-areas:
			"banner1 banner1"
			"banner2 banner3";
		gap: 10px 4px;
	}
} */



.banner_part_a {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 100%;
	
}

.banner1_part {
	grid-area: banner1;
	/*justify-self: center;*/
	
	position: relative;

	max-width: 100vw;
	width: 100%;
	height: calc(100vw / 1024 * 380);

	overflow: hidden;
}
@media screen and (min-width: 700px) {
	.banner1_part {
		width: 100%;
		height:100%;
		height: calc(90vw / 1024 * 380);
	}
}
@media screen and (max-width: 700px) {
	.banner1_part {
		width: 100%;
		height:100%;
		height: calc((90vw / 1024 * 380) + 32px);
		height: calc((100vw / 1024 * 380));
	}
}
.banner1_placeholder {
	content: '';
	height: 100%;
}

.swiper_image{
	/*TODO banner*/
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-fit: fill;
	object-fit: contain;
}
.swiper_banner_part {
	width: 100%;
	height: calc(100vw / 1024 * 380);
}
@media screen and (min-width: 700px) {
	.swiper_banner_part {
		width: 100%;
		height: 100%;
	}
}
.banner2_part {
	grid-area: banner2;

	position: relative;
	
	width: 100%;
	/* height: calc((100vw / 670 * 300)); */

	overflow: hidden;
}
@media screen and (min-width: 700px) {
	.banner2_part {
		justify-self: start;

		width: 100%;
		height: 100%;
	}
}
.banner2_placeholder {
	position: relative;
	height: 100%;

	text-align: center;
	
}


.banner2_placeholder img{
	width: 100%;

}
.banner2_timer_placeholder {
	position: relative;
	display: inline-grid;
	grid-template-columns: auto auto auto auto auto auto auto;
	align-items: center;
	gap: 0 2px;
	transform-origin: left bottom;
	justify-items: center;
}
@media screen and (min-width: 700px) {
	.banner2_timer_placeholder {
		position: absolute;
		left: 8%;
		bottom: 5%;

		display: grid;
		grid-template-columns: auto auto auto auto auto auto auto;
		align-items: center;

		gap: 0 2px;

		transform: scale(1);
		transform-origin: left bottom;
	}
}
.icon_clock {
	display: inline-block;
	width: 18px;
	height: 18px;

	/* background-image: url(../../img/icon/clock.svg); */
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;

	margin-right: 8px;
}
.timer_box {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 16px;

	background-color: white;
	border-radius: 4px;
	color: #FF873B;
}
.banner3_part {
	grid-area: banner3;

	position: relative;
	
	display: grid;

	width: 100%;
	height: calc(100vw / 348 * 406);

	overflow: hidden;
}
@media screen and (min-width: 700px) {
	.banner3_part {

		width: 100%;
		height: 100%;
	}
}

.banner3_placeholder {
	position: relative;
	height: 100%;

	text-align: center;



}

.banner3_placeholder img {
	width: 100%;
}
@media screen and (min-width: 700px) {
	.banner3_placeholder img {
	}
	.banner2_img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.banner3_img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.swiper_banner_inner_container
{
}

.swiper_banner_inner_container > *
{
}

.hand
{
	cursor: pointer;
}
.no_hand
{
    height: 100%;
	cursor: default;
}

@media screen and (max-width: 1440px) {
	.mainBannerContainer{
		height: 24vw;
	}
	.secondBannerContainer{
		height: 24vw;
	}
}

@media screen  and (orientation: portrait) and (max-width: 768px) {

 
    .recommendationContainer{
        margin:2em  0 1em 0em;
    }
   
}


@media screen and (max-width: 1025px) {
    .recommendationContainer{
        margin:2em  0.6em 1em 0em;
    }
    
}


@media screen and (max-width: 719px) {
	.mainBannerContainer{
        width: 100%;
		/* padding-right: 0; */
		height: initial;
    }
    
	.TreeMeunContainer{
		margin:0 1em 0 0;
	}
	.secondBannerContainer{
		display: none;
		margin-left: 0;
	}
    .bannerContainer{
        height: initial;
        flex-direction: column;
    }
    
}

@media screen and (max-width: 719px) {
	.homeInnerContainer {
		flex-direction: column;
	}
	.bannerContainer{
		width: 100%;
	}

	.mainBannerContainer {
		height: initial;
	}
}