.TableCardViewContainer {
    min-width: 200px;
    max-width: 300px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    border-radius: 8px;
    max-height: 300px;

    margin-top: 10px;


    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */

    cursor: pointer;
}

.TableCardView_IMG {
    display: flex;
    justify-content: center;
    margin: 0 8px 0;
    padding-top: 8px;
    height: 200px;
    object-fit: contain;
}

.TableCardView_IMG img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    width: 140px;
    object-fit: contain; */
}
.TableCardViewContent>div {

    margin: 4px;
   
}

.TableCardViewContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* align-items: flex-end; */
    align-content: space-between;
    padding: 8px;
}

.TableCardViewContent h5{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.TableCardViewContainer ::-webkit-scrollbar-track, .TableCardViewContent ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  .TableCardViewContainer::-webkit-scrollbar, .TableCardViewContent::-webkit-scrollbar {
    width: 2px;
    background-color: white;
  }

  .TableCardViewContainer::-webkit-scrollbar-thumb , .TableCardViewContent::-webkit-scrollbar-thumb{
    background-color: black;
  }