@import '/src/assets/color.css';

#root {
  position: absolute;
  width: 100%;
  height: 100vh;

  position: fixed;
  height: 100vh;
  height: 100%;
  /* height: fit-content; */
  overflow-y: auto;
}
.epub#root {
  overflow-x: hidden;
}

.viewer {
  height: 100%;
}
.view-area {
  height: 100%;
  touch-action: none;
  position: relative;
}

@keyframes fade-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: -10;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fade-down {
  0% {
    transform: translateY(-60px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes fade-up {
  0% {
    transform: translateY(60px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
.panel-icon {
  color: white;
  font-size: 20px;
  opacity: 0.9;
}
.left-panel {
  width: 50px;
  height: 250px;
  position: absolute;
  left: 0px;
  top: calc(50vh - 125px);
  background-color: rgba(75, 75, 75, 0.3);
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 10;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.right-panel {
  width: 50px;
  height: 250px;
  position: absolute;
  right: 0px;
  top: calc(50vh - 125px);
  background-color: rgba(75, 75, 75, 0.3);
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  z-index: 10;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.top-panel {
  width: 250px;
  height: 50px;
  position: absolute;
  right: calc(50vw - 125px);
  top: 0px;
  background-color: rgba(75, 75, 75, 0.3);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  z-index: 10;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bottom-panel {
  width: 250px;
  height: 50px;
  position: absolute;
  right: calc(50vw - 125px);
  top: calc(100vh - 50px);
  top: calc(100% - 50px);
  background-color: rgba(75, 75, 75, 0.3);
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 10;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.operation-panel-container {
  width: 412px;
  height: 60px;
  position: absolute;
  top: 0px;
  left: calc(50vw - 206px);
  z-index: 15;

  transition: transform 0.5s ease;
}
.progress-panel-container {
  width: 412px;
  height: 60px;
  position: absolute;
  top: calc(100vh - 60px);
  top: calc(100% - 60px);
  left: calc(50vw - 206px);
  z-index: 15;
  z-index: 14;
  transition: transform 0.5s ease;
  /* display: none; */
}
.setting-panel-container {
  width: 299px;
  height: 100vh;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: transform 0.5s ease;
  z-index: 15;
}

.navigation-panel-container {
  width: 299px;
  height: 100vh;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: transform 0.5s ease;
  z-index: 15;
  background-color: white;
}
/* .navigation-panel-container.open {

} */
.navigation-panel-container-handle {
  position: absolute;
  top: 50%;
  right: -40px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 60px;

  margin-top: -30px;

  background-color: white;

  border-radius: 0 30px 30px 0;
  
  transition: transform 0.1s;

  box-shadow: 0px 0px 4px 4px var(--color-black-alpha30);

  cursor: pointer;
}
/* .navigation-panel-container.open .navigation-panel-container-handle {
  
} */

.view-area-page-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-area-page-init {
  width: 100%;
  height: 100%;
}
.view-area-page {
  
  /*
  width: 100%;
  height: 100%;
  position: absolute;
  width: 80%;
  height: 90vh;
  
  transform: scale(0.7961460446247465) translate(0px, 0px); 
  */
  /*
  position: absolute;
  left: 0px;
  right: 0px;
  top: 28px;
  bottom: 25px;
  */
  /* width: calc(100% - 100px);
  height: calc(100% - 100px); */
  z-index: 5;
  user-select: text;
}
.previous-chapter-single-container {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  position: absolute;
  bottom: 15px;
  left: 20px;
  font-size: 30px;
  /* transform: rotate(90deg); */
  cursor: pointer;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: url('../../../assets/reader/reader_img/back.svg');
  background-size: contain;
  background-size: 70% 70%;
  background-position: center;
  background-repeat: no-repeat;
}

.next-chapter-single-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  bottom: 15px;
  right: 20px;
  /* transform: rotate(-90deg); */
  cursor: pointer;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: url('../../../assets/reader/reader_img/next.svg');
  background-size: contain;
  background-size: 70% 70%;
  background-position: center;
  background-repeat: no-repeat;
}
.next-chapter-single-container:hover {
  opacity: 0.7;
}
.previous-chapter-single-container:hover {
  opacity: 0.5;
}
.previous-chapter-single {
  height: 20px;
  font-size: 20px;
}
.next-chapter-single {
  font-size: 20px;
  height: 20px;
}
.reader-setting-icon {
  margin: 8px;
  opacity: 0.5;
}
.reader-setting-icon-container {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -3px;
  right: 35px;
  font-size: 20px;
  cursor: pointer;
  z-index: 10;
  transition: 0.1s;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0, 2s;
  background-image: url('../../../assets/reader/reader_img/menu.svg');
  background-size: contain;
  background-size: 70% 70%;
  background-position: center;
  background-repeat: no-repeat;

  top: 5px;
  right: 12px;
}
.reader-setting-icon-container:hover {
  border-radius: 50%;
}
.add-bookmark-button2{
  width: 50px;
  height: 50px;
  position: absolute;
  top: -3px;
  right: 120px;
  font-size: 10px;
  cursor: pointer;
  z-index: 10;
  transition: 0.1s;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0, 2s;
  color: black;

  background-image: url('../../../assets/reader/reader_img/bookmark_off.svg');
  background-size: contain;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  right: 62px;
}
.add-bookmark-button2.on{
  background-image: url('../../../assets/reader/reader_img/bookmark_on.svg');
}
.add-bookmark-text2 {
  display: none;
}

.icon-play{
  width: 50px;
  height: 50px;
  position: absolute;
  top: -3px;
  right: 75px;
  right: 112px;
  font-size: 10px;
  cursor: pointer;
  z-index: 10;
  transition: 0.1s;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0, 2s;
  /* background-image: url('../../../assets/reader/reader_img/menu.svg');
  background-size: contain;
  background-size: 70% 70%;
  background-position: center;
  background-repeat: no-repeat; */
}

.note-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  /*
  position: absolute;
  bottom: 15px;
  left: 20px;
  */
  font-size: 30px;
  /* transform: rotate(90deg); */
  cursor: pointer;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: url('../../../assets/reader/reader_img/note.svg');
  background-size: contain;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  width: 40px;
  height: 40px;
  opacity: 1;
}
.icon-note:before {
  content: "" !important;
}

.digest-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  /*
  position: absolute;
  bottom: 15px;
  left: 20px;
  */
  font-size: 30px;
  /* transform: rotate(90deg); */
  cursor: pointer;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: url('../../../assets/reader/reader_img/highlight.svg');
  background-size: contain;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  width: 40px;
  height: 40px;
  opacity: 1;
}
.icon-highlight:before {
  content: "" !important;
}

.translation-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  /*
  position: absolute;
  bottom: 15px;
  left: 20px;
  */
  font-size: 30px;
  /* transform: rotate(90deg); */
  cursor: pointer;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: url('../../../assets/reader/reader_img/translate.svg');
  background-size: contain;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  width: 40px;
  height: 40px;
  opacity: 1;
}
.icon-translation:before {
  content: "" !important;
}

.copy-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  /*
  position: absolute;
  bottom: 15px;
  left: 20px;
  */
  font-size: 30px;
  /* transform: rotate(90deg); */
  cursor: pointer;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: url('../../../assets/reader/reader_img/copy.svg');
  background-size: contain;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  width: 40px;
  height: 40px;
  opacity: 1;
}
.icon-copy:before {
  content: "" !important;
}

.search-book-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  /*
  position: absolute;
  bottom: 15px;
  left: 20px;
  */
  font-size: 30px;
  /* transform: rotate(90deg); */
  cursor: pointer;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: url('../../../assets/reader/reader_img/in_book_search.svg');
  background-size: contain;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  width: 40px;
  height: 40px;
  opacity: 1;
}
.icon-search-book:before {
  content: "" !important;
}

.google-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  /*
  position: absolute;
  bottom: 15px;
  left: 20px;
  */
  font-size: 30px;
  /* transform: rotate(90deg); */
  cursor: pointer;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: url('../../../assets/reader/reader_img/internet_search.svg');
  background-size: contain;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  width: 40px;
  height: 40px;
  opacity: 1;
}
.icon-google:before {
  content: "" !important;
}

.speaker-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  /*
  position: absolute;
  bottom: 15px;
  left: 20px;
  */
  font-size: 30px;
  /* transform: rotate(90deg); */
  cursor: pointer;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: url('../../../assets/reader/reader_img/speaker.svg');
  background-size: contain;
  background-size: 90% 90%;
  background-position: center;
  background-repeat: no-repeat;

  width: 40px;
  height: 40px;
  opacity: 1;
}
.icon-Speaker:before {
  content: "" !important;
}

@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 550px) {
  .progress-panel-container {
    top: calc(100vh - 60px);
    top: calc(100% - 60px);
    left: calc(50vw - ( (100vw - 80px - 80px) / 2) );
    width: calc(100vw - 80px - 80px);
    height: 60px;
  }
  .progress-panel {
    width: calc(100vw - 80px - 80px) !important;
    left: 0;
  }
  input.input-progress[type="range"] {
    width: calc(100vw - 80px - 80px) !important;
  }

  .progress-panel-container .progress_str {
    position: absolute !important;
    left: 0 !important;
    top: 8px !important;
    width: 100%;
    text-align: center;

    font-size: 14px;
    pointer-events: none;
  }
}