.loginContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 8px;
    height: calc(100vh - 45px);
    height: calc(100vh - 150px);
    /* height: calc(100vh - 20px); */
    align-content: center;
}

.loginContainer2{
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 8px;
    height: calc(100vh - 45px);
    height: calc(100vh - 150px);
    /* height: calc(100vh - 20px); */
    align-content: center;
}


.loginContent{
    height: calc(100vh - 45px);
}
.loginContainer img {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 340px; */
    /* height: 120px; */
    /* margin-bottom: 50px;
    margin-right:10px; */
    margin: 10px;
}

.logoImg{
    width: 100%;

}

.logoTitleDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    white-space: nowrap;
        flex-direction: row;
}

.row1,
.row2,
.row3,
.row4,
.row5,
.row6,
.row7,
.row8,
.row9{
    display: grid;
    grid-column: 2 / 3;
}
 
.row2,
.row3,
.row4,
.row5,
.row6,
.row7,
.row8 {
    /* margin-left: 40px; */
}

.row6 {
    grid-template-columns: 20px auto auto;
}

.row6sub1 {
   display: flex;
}

.row6sub3 {
    justify-self: end;
}

.loginContainer .row7 {
    margin-top: 20px;
    margin-top: 50px;
}

.row8 {
    margin-top: 85px;
    text-align: center;
    font-size: small;
    color: darkgray;
}

.row7 button:disabled{
    width: 120px;
    margin: 0 auto;
}


.LoginbtnRow{
    display: flex;
    align-items: center;
    justify-content: center;
}

.LoginbtnRow button{
    width:60%;
    margin: 0 3px;
}

@media screen and (max-width: 768px) {
    .logoTitleDiv{
    flex-direction: column;
    }

    .logoImg{
        width: 260px;
        margin: 20px 0;
    }
}