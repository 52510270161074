

.footer{
    background-color: #f4f4f4;
    height: 6vh;
    display:flex;
    align-items: center;
    justify-content: center;

    
    width: 100%;
    bottom: 0;
    position: relative;
    /* position: fixed; */
}

.innerFooter{
    max-width:1440px;
}

.innerFooter ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0 1em 0 1em;
    color: white;
    font-size: 0.7rem;
    color: var(--color-red-main);
}


.innerFooter ul li a{
    color: var(--color-red-main);
}