.tableItemList {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 8px;
}

.tableItemList:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.tableItemListContainer {
    margin: 0 auto 8px;
    padding: 0 8px 8px;
}

.tableListItemGridView {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 30px repeat(2, 1fr) 30px 30px;
    justify-content: space-between;
}

/* .ListViewCol1 {
    padding-left: 8px;
} */

.tableItemCard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 8px;
    height: auto;
}

.tableItemCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.tableItemCardContainer {
    /* margin: 0 auto 8px; */
    padding: 8px;
}

.tableListItemGridView {
    display: grid;
    /* grid-template-columns: auto 1fr 1fr; */
    grid-template-columns: 30px repeat(2, 1fr) repeat(2, 0.5fr) 30px 30px;
    justify-content: space-between;
    word-break:break-all;

    /* grid-template-areas: 
        "main main2 main2"
        ". side side"
        ". isbn isbn"
        ". footer footer"; */
}

.GridViewCol1 {
    grid-area: main2;

    padding-left: 8px;
}

.GridViewCol2 {
    grid-area: main;

    display: flex;
    padding-right: 8px;
    justify-content: end;
}
.GridViewCol3 {
    grid-area: side;

    padding-top: 8px;
    padding-left: 8px;

}

.GridViewCol4 {
    grid-area: isbn;
}


.GridViewCol5 {
    grid-area: footer;
}

.GridViewCol6 {
    grid-area: footer;

    display: flex;
    justify-content: end;
    justify-content: flex-end;

    margin-top: 8px;
}