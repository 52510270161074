@import '/src/assets/color.css';

.usersVC_header {
    position: sticky;
    top: 45px;

    background-color: white;

    /* z-index: 1; */
}

.usersViewContainer {
    padding: 8px 10px 0;
    /* 
    display: flex;
    flex-direction: column;
    height: calc(100vh - 45px);
    */
}

.usersViewContainer h4 {
    padding: 8px 8px 0;
}

.backbtn{
    display: flex;

    align-items: center;
    /* padding: 25px 35px 0; */
}

.dataContentView{
    /*
    height: calc(100vh - 270px);
    overflow-y:auto;
    */
}

.usersViewHeader {
    display: grid;
    grid-template-columns: 300px auto 200px;
    grid-template-columns:  auto auto;
    padding: 16px 8px 16px;
}

.usersViewRowBtn{
    margin-left: -45px;
}

.usersViewRow{
    white-space: nowrap;
    display: flex;
}

.usersViewRow > button {
    margin-left: 8px;
}

.usersViewRow input{
 border-radius: 25px;
}

.usersViewRowSpan {
    display: grid;
    grid-template-columns: 120px 20px 120px ;
    grid-gap: 8px;
    direction: rtl;
    align-items: center;
}

.usersViewUl {
    list-style-type: none;
    position: sticky;
    width: 100%;
    padding: 0 8px 0;
    overflow: hidden;
}

.usersViewLi {
    float: left;
    width: 33.333%;
    /* color: #995D3D; */
    color : var(--color-brown);

}

.usersViewLi button {
    display: block;
    color: black;
    font-size: 32px;
    text-align: center;
    width: 100%;
    padding: 10px 20px;
    font-weight: bold;
    text-decoration: none;
    border: none;
    border-bottom: 4px solid  var(--color-black-add90);
    background-color: #FFF;
}

.usersViewLi>button:focus {
    color:  var(--color-blue-add25);
    border-bottom: 4px solid  var(--color-blue-add25);
}

.tableHeader {
    display: grid;
    grid-template-columns: 30px repeat(2, 1fr) repeat(2, 0.5fr) 60px;
    grid-gap: 16px;
    justify-content: space-between;
    padding-bottom: 8px;
}

.tableHeaderItemCol1 {
    padding-left: 8px;
}

.cardViewContainer {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
}

.border_line{
    border-right: 2px solid;
    height: 100%;
    margin: 0 8px;
}

.overflow_content{
    height: 380px;
    overflow-y: auto;
}

.button_on_right{
    display: flex;
    margin-top: 15px;
    justify-content: flex-end;
}


.button_on_right button{
    margin: 0 5px
}

.overflow_content ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  .overflow_content::-webkit-scrollbar {
    width: 2px;
    background-color: white;
  }

  .overflow_content::-webkit-scrollbar-thumb {
    background-color: black;
  }



@media screen and (max-width:560px) {
    .usersViewHeader {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .usersViewContainer {
        padding: 8px 25px 0;
    }
}
