.backbtn {
    display: flex;

    align-items: center;
    /* padding: 25px 35px 0; */
}

.actionButtonContainer {
    width: 30px;
    margin-left: auto;
    margin-right: 100px;
}

.listContainer {
    padding: 8px;
}

.listItem {
    max-height: 70px;
    min-height: 65px;
    width: 100%;
    display: flex;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
    margin: 10px 0;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}

.inputField > * {
    width: 30px;
}