.tableItemList {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 8px;
}

.tableItemList:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.tableItemListContainer {
    margin: 0 auto 8px;
    padding: 0 8px 8px;
}

.tableListItemGridView {
    display: grid;
    grid-gap: 8px;
    grid-template-columns:  2fr 100px ;
    justify-content: space-between;
    padding: 16px 0 8px;
    align-items: center;
}

.TableListviewRow_grid{
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 150px ;
    height: 100%;
    align-items: center;
}



 /* .boldText{
     font-weight: bold;
     margin-bottom: 5px;
     color: var(--color-blue-add25);
     margin-left: 15px;
 } */

 .actionLogHighlightText{
    color: var( --color-orange);
}