:root{
    --color--white-reduce30: #B2B2B2;
    --color--white-reduce15: #D9D9D9;
    --color--white-reduce15-alpha50: #D9D9D980;
    --color--white: #FFFFFF;

    --color--white--reduce:#fdefe8;

    --color-brown-reduce30: #6A402A;
    --color-brown-reduce15: #824F33;
    --color-brown: #995D3D;
    --color-brown-add30: #B78D77;
    --color-brown-add70: #E0CEC5;
    --color-brown-add90: #F5EFEB;

    --color-black-reduce30: #212121;
    --color-black-reduce15: #282828;
    --color-black: #303030;
    --color-black-add30: #6E6E6E;
    --color-black-add70: #C1C1C1;
    --color-black-add90: #EAEAEA;
    --color-black-re1 : #333333;
    --color-black-re12 :#123533;
    --color-black-alpha30: rgb(0 0 0 / 30%);
    
    --color-orange-reduce30: #9E5529;
    --color-orange-reduce15: #C16833;
    --color-orange: #E37B3C;
    --color-orange-add30: #EBA276;
    --color-orange-add70: #F6D7C4;
    --color-orange-add90: #FCF2EB;

    --color-orange-add: #ef986f;

    --color-organge-main:#dd7636;
    --color-red-main : #b53c3f;

    --color-blue-add25: #47D5CD;
    --color-blue-add30: #47D5CD4D;
    --color-blue-add75: #bfe6e8;
    --color-blue-add1: #009da5;
    --color-blue-reduce50:  #214753;
}