.Header {
    display: flex;
    justify-content: space-between;
}
  
  .backHomeButtonContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .backHomeButtonContainer  img {
    /* height: 15px; */
    margin: 0 1em 0 0;
  }
  .backHomeButtonContainer  button {
    color: rgba(181 60 63);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  


.margin_7px{
    margin: 7px;
}

.title{
 
}

.ContentItem_container{
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(5, 1fr);

    grid-template-columns:repeat(auto-fill, minmax(200px, 1fr));
    justify-content: space-between;
    padding: 25px;
}

.ContentLayout {
    display: flex;
    margin: 2em 0 0 0;
    flex-direction: column;
}
  
.displayFlex{
  display: flex;
}

.marginTOP_10{
    margin-top: 10px;
}

