.butoonGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .butoonGroup button {
    color: white;
    background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
    width: 50px;
    border-radius: 50px;
    height: 25px;
    font-size: 0.7em;
  }
  .butoonGroup img {
    width: 80%;
  }
  
  .bookLayout {
    /* width: 130px;
    height: 280px; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    min-width: 130px;
    width: 150px;

    margin: 1em;
    flex: 0 0 auto;
  }
  .bookImgContainer {
    width: 100%; 
    /*height: 65%;
     box-shadow: 1px 0px 8px -2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 0px 8px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 8px -2px rgba(0, 0, 0, 0.75); */
  }

  
  .bookImgContainer > img {
    width: 100%;
    /* height: 100%; */
    object-fit: fill;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    max-height: 190px;
    cursor: pointer;
  }

 .bookNameLayout h5{
    margin: 0.5em 0 0.5em 0;
  }
  



   @media screen and (max-width : 768px) {

      .bookLayout{
        /* margin: 10px 10px 10px; */
      }
    
   }