.container {
  position: fixed;
  z-index: 2;
  padding-top: 200px;
  padding-top: 60px;
  padding-top: 15vh;
  padding-top: 20vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.contentContainer {
  background-color: #fefefe;
  margin: auto;
  padding: 35px 30px 35px;
  border-radius: 8px;
  border: 1px solid #888;
  width: 500px;
  word-break: break-all;
  height: 600px;
  position: relative;

  max-height: 600px;
  min-height: 470px;
  /* height: auto; */
}

.content {
  padding: 8px;
  height: 100%;
}

.content5{
  padding: 8px;
  max-height: 40vh;
  overflow-y: auto;
}
.scroll {
  padding: 8px;
  height: 50vh;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 90%;
}

.title {
  padding: 4px;
  margin: 12px 0;
}

.control {
  display: flex;
  direction: rtl;
  padding: 8px;
}

.container ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.container::-webkit-scrollbar {
  width: 2px;
  background-color: white;
}

.container::-webkit-scrollbar-thumb {
  background-color: black;
}

.container2 , .container3 {
  position: fixed;
  z-index: 5;
  padding-top: 200px;
  padding-top: 60px;
  padding-top: 26vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.conflictCategoryDialog {
  position: fixed;
  z-index: 5;
  padding-top: 10%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.contentContainer2,  .contentContainer4 {
  background-color: #fefefe;
  margin: auto;
  padding: 35px 30px 15px;
  border-radius: 8px;
  border: 1px solid #888;
  width: 500px;
  word-break: break-all;
  position: relative;
  height: auto;
}

.conflictCategoryDialogContent {
  background-color: #fefefe;
  margin: auto;
  padding: 35px 30px 15px;
  border-radius: 8px;
  border: 1px solid #888;
  width: 800px;
  word-break: break-all;
  position: relative;
  height: 800px;
}

.contentContainer3 {
  background-color: #fefefe;
  margin: auto;
  padding: 35px 30px 15px;
  border-radius: 8px;
  border: 1px solid #888;
  width: auto;
  max-width: 400px;
  min-width: 200px;
  word-break: break-all;
  position: relative;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
}


.contentContainer5{
  background-color: #fefefe;
  margin: auto;
  padding: 35px 30px 15px;
  border-radius: 8px;
  border: 1px solid #888;
  width: 500px;
  word-break: break-all;
  position: relative;
  height: auto;
  max-height: 60vh;
}

.content3 {
  padding: 8px;
  width: 100%;
 
}


.bookmarkRecord_row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.content5.bookmarkRecord_row_btn{
  display: flex;
}


@media screen and (max-width: 768px) {
  .contentContainer,   .contentContainer2  {
    width: 90%;
    height: 90%;
  }

  .contentContainer3, .contentContainer4, .contentContainer5 {
    width: 90%;
  }

  .contentContainer5 {
  padding: 15px 10px 15px;
  }

}