.honrizontalList {
  /* height: 300px; */
  box-shadow: 1px -1px 5px 1px rgb(235 235 235);
  border-radius: 5px;
  margin:1em 0 2em 0;
}
.honrizontalListHeaderLine {
  height: 8px;
  background-color: var(--color-orange-add);
  border-radius: 5px 5px 0 0;
}

.header {
  /*TODO header title*/
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  background-color: blanchedalmond;
  padding: 0 1em 0 1em;
}
.moreContainer {
  height: 100%;

  display: flex;
    align-items: center;
}

.moreContainer > a {
  font-size: 1em;
  color: rgba(181 60 63);
}

.scrollingWrapper {
  /* height:100%; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 0 1em 0 1.5em;
}
