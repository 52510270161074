.base {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.header {
    background-image: linear-gradient(to right, rgb(213, 128, 74), rgb(169, 76, 73));
    position: fixed;
    z-index: 2;
    width: 100%;
}

.headerLogo {
    margin-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    height: 3rem;
}

.content {
    background-color: white;
    flex-direction: column;
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    text-align: center;
}

.contentLogo {
    width: inherit;
    max-width: 90%;
    margin-top: 8rem;
    margin-bottom: 5rem;
}

.select_area {
    margin: auto;
    max-width: 550px;
    width: auto;
    text-align: start;
    margin-bottom: 10rem;
    margin-bottom: 5em;
}

.select_field {
    display: flex;
    justify-content: space-between;
    height: 3rem;
    height: fit-content;
    border-radius: 5px;
    border: 2px solid rgb(213, 213, 213);
    background-color: rgb(234, 234, 234);
    margin-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.orgsName {
    display: inline;
    line-height: 3rem;
}

.select_controll {
    background-color: transparent;
    border: none;
    color: rgb(201, 125, 76);
    /* margin-left: 375px; */
    cursor: pointer;
}

.submitDisabled {
    height: 3rem;
    width: 150px;
    border-radius: 3rem;
    border: 0px;
    /* color: rgb(217, 217, 217); */
    cursor: pointer;
}

.submit {
    height: 3rem;
    width: 150px;
    border-radius: 3rem;
    border: 0px;
    background-image: linear-gradient(to right, rgb(213, 128, 74), rgb(169, 76, 73));
    color: white;
    cursor: pointer;
}

.dialogCover {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.dialog {
    background-color: #FEFEFE;
    /* margin: 10%; */
    padding: 3.5rem;
    border-radius: 8px;
    border: 1px solid #888;
    word-break: break-all;
    width: 80%;
    height: 80%;
    position: relative;

    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/* 
.dialogHeader {
    display: flex;
    justify-content: space-between;
} */

.dialogNavbtnSelected {
    background-color: transparent;
    border: none;
    color: rgb(201, 125, 76);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0.5rem;
    border-bottom: 5px solid rgb(201, 125, 76);
}

.dialogNavbtn {
    background-color: transparent;
    border: none;
    color: black;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0.5rem;
    border-bottom: 5px solid rgb(216, 216, 216);
}

/* 
.dialogClose {
    background-color: transparent;
    border: none;
    color: rgb(201, 125, 76);
} */

.dialogContent {
    margin-top: 3rem;
}

.searchField {
    width: 100%;
    display: grid;
    grid-template-columns: 9fr 1fr;
}

.orgTitle {
    position: sticky;
    top: 0;
    background-color: white;
    padding-bottom: 1rem;
}

.orgList {
    margin-top: 3rem;
    height: 340px;
    height: 290px;
    overflow: scroll;
}

.orgItem {
    width: 100%;
    border-bottom: 1px solid black;
    text-align: start;
    padding: 1rem;
}

.orgItemCheck {
    width: fit-content;
    accent-color: rgb(201, 125, 76);
    ;
}

.orgItemName {
    display: inline;
    margin-left: 1rem;
    cursor: pointer;
}

.dialogReturn {
    width: auto;
    /* position: absolute; */
    bottom: 0;
    right: 0;
    padding: 5%;
    display: flex;
    justify-content: flex-end;
}

.cancel {
    height: 3rem;
    width: 150px;
    border-radius: 3rem;
    border: 0px;
    background-color: rgb(241, 216, 197);
    color: rgb(201, 125, 76);
    margin-right: 1rem;
    cursor: pointer;
}


.MainSectionUl {
    list-style-type: none;
    position: sticky;
    width: 100%;
    /* padding: 0 100px 0; */
    overflow: hidden;
    color: #cccccc;
    /* margin-top: 60px; */
    display: flex;
    align-items: flex-end;
}

.MainSectionLi {
    float: left;
    width: 33.333%;
    color: rgb(201, 125, 76);
    font-size: 0.8em;
    /* padding: 0 0.8em 0; */
    cursor: pointer;
}

.MainSectionLiSelected{
    float: left;
    width: 33.333%;
    color: rgb(201, 125, 76);
    font-size: 0.8em;
     padding: 0 0.2em 0; 
     cursor: pointer;
}



.MainSectionLi a {
    display: block;
    color: black;
    font-size: 1.5em;
    text-align: center;
    padding: 10px 20px;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 4px solid var(--color-black-add90);
}

.MainSectionLiSelected a{
    display: block;
    color: var(--color-orange);
    font-size: 1.5em;
    text-align: center;
    padding: 10px 20px;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 4px solid var(--color-orange);
}

.MainSectionLi a:focus
{
    color: var(--color-brown-reduce15);
    border-bottom: 4px solid var(--color-brown-reduce15);
}

.MainSectionLiSelected a:focus
{
    color: var(--color-orange);
    border-bottom: 4px solid var(--color-orange);
}

.MainSectionUl ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  .MainSectionUl::-webkit-scrollbar {
    width: 2px;
    background-color: white;
  }

  .MainSectionUl::-webkit-scrollbar-thumb {
    background-color: black;
  }


@media screen and (max-width: 428px) {

   

    .dialog {
        /* margin: 20px auto; */
        padding: 20px;
        height: auto;
    }

    .dialogReturn {
        display: flex;
        position: initial;
    }

    .cancel {
        width: 32vw;
    }

    .submit {
        width: 32vw;
    }

    .dialogHeaderNav {
        overflow: scroll
    }

    .contentLogo {
        margin-left: 20px;
        margin-right: 20px;
    }

    .select_area {
        margin-left: 20px;
        margin-right: 20px;
    }
}


@media screen and (max-height: 667px) {

    .dialog {
        /* margin: 20px auto; */
        padding: 20px;
    }

    .dialogReturn {
        display: flex;
    }

    .cancel {
        width: 32vw;
    }

    .submit {
        width: 32vw;
    }

    .dialogHeaderNav {
        overflow: scroll
    }

    .contentLogo {
        margin-left: 20px;
        margin-right: 20px;
    }

    .select_area {
        margin-left: 20px;
        margin-right: 20px;
    }

    .orgList {
        height: 35vh;
        margin-top: 10px;
    }
}

@media screen and (min-width: 1024px) {
    .contentLogo{
        width: 1024px;
        max-width: 710px;
        width: 60%;
    }
}