@import '/src/assets/color.css';

.tableItemListContainer {

    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
}


.topRow {
    font-weight: bold;
    padding: 6px;
    background-color:  var(--color-orange-add);;
    border-radius: 10px 10px 0 0;
}


.content{
    height: 100%;
    width: 100%;
    padding: 15px 0;
}

.padding{
    padding: 0 17px 17px;
}

.title{
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}


.scrollingWrapper {
       /* height: 100%; */
       display: flex;
       flex-wrap: nowrap;
       overflow-x: auto;
       margin: 1em 1em 0 0;
}

.recordArea{
    font-weight: bold;
    padding: 20px;
}

.ClientTitle{
    color: darkgray;
}

.btn{
font-size: 12px;
color: var(--color-orange-add)
}



@media screen and (max-width: 768px){
    .padding{
        padding: 0 15px 17px;
    }
}