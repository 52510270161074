
.Header {
    display: flex;
    justify-content: space-between;
}
  
  .backHomeButtonContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .backHomeButtonContainer  img {
    /* height: 15px; */
    margin: 0 1em 0 0;
  }
  .backHomeButtonContainer  button {
    color: rgba(181 60 63);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }



.eventConatiner{
    padding: 30px;
}

.eventConatiner label{
    font-size: 18px;
    margin: 8px 0;
}


.eventConatiner input{

    margin: 8px 0;
}

.eventRadio{
    display: flex;
    align-items: center;
}

.eventRadio input[type='radio']{
    width: 25px;
    min-width: auto;
    margin: 7px 20px;
}


.event_button{
    margin: 10px;
    display: flex;
    justify-content: flex-end;
}

.event_button button{
    width: auto;
    padding: 1%;
}