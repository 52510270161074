.mainLayoutContainer{
    max-width: 1440px;
    margin:0 auto;
    max-width: 1520px;
    min-height: calc(100vh - 12vh - 6vh);
    
}

.mainContainer{
    padding: 8px 20px;
    height: 100%;
}

.mainLayoutInnerContainer{
    margin: 1em 2em 0 2em;
    padding: 0 0 7vh;
}

.mainLayoutInnerContainer2{
    margin: 1em 0 0 0;
    padding: 0 0 7vh;
}




.floatingSidebar{
    position: absolute;
    display: none;
    width: 300px;
    height:100vh
}

.closeButton{
    display:flex;
    align-items: center;
    margin:2em 0 2em 2em;
}

.closeButton > button{
    font-size:1rem;
    margin: 0 0 0 1em;
}



@media screen  and (orientation: portrait) {

}

@media screen and (max-width: 1025px) {
    .mainLayoutInnerContainer{
        margin: 1em 0em 0 0em;
    }
}


@media screen and (max-width: 360px) {
    .mainLayoutContainer{
        padding: 8px 10px;
    }
}