.bookContainer {
  flex: 0 0 13%;
  margin: 1em 2em 1.5em 0;
  display: flex;
  flex-direction: column;
}

.bookImgContainer {
  display: flex;
  justify-content: center;
  width: 140px;
  height: 160px;
  position: relative;
}

.bookContainer * {
  color: black;
  /* width:130px */
}

.bookContainer h5 {
  width: 110px;
  margin-bottom: 7px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.bookContainer p {
  font-size: 0.8rem;
  width: 110px;
}

.bookImgContainer img {
  /* height:100%; */
  object-fit: contain;
  /* margin: 0 0 0.5em 0; */
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  max-height: 160px;
  max-width: 120px;
}

.bookflex {
  display: flex;
  flex-direction: column;
}

.bookImgButtonGroup {
  display: none;
  /* background-color: rgba(0, 0, 0, 0.2); */
  position: absolute;
  width: 140px;
  height: 150px;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
}

.bookImgContainer:hover .bookImgButtonGroup {
  display: flex;
}

.bookImgButton {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  /* height: 30px; */
  flex: 1;
  border-radius: 20px;
  border: 0px;
  background-color: rgba(232 112 60);
  /* color: white; */
  cursor: pointer;
}

.dialogButtonContainer, .dialogButtonContainer2{
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}


.dialogButtonContainer3{
  display: flex;
  justify-content: center;
  margin-top: 15px;
  align-items: center;
}



.pad div{
  padding: 4px 0;
}
.pad > div {
  display: inline;
}

.pad button{
  font-size: 0.8em;
}
.pad .pad_small_text {
  font-size: 70%;
}

@media screen and (max-width: 1025px) {
  .bookContainer {
    flex: 0 0 23%;
    /* flex: 0 0 auto; */
    margin: 1em 2em 1.5em 0;
  }
}

@media screen and (max-width: 428px) {
  .pad > div {
    display: block;
  }
}