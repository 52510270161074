.container {
    display: flex;
    flex-direction: column;

}

.gridRowErrorMsg {
    grid-column: 1 / span 3;
}

.displayflex{
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-top: 10px;
}

.button_on_right{
    display: flex;
    position: absolute;
    justify-content: flex-end;
    bottom: 15px;
    right: 35px;
}

.label_style{
    width: 40%;
    font-weight: bold;
}

.input_style{
    width: 100%;
}

.margin_left{
    margin: 0 0 0 10px;
}


.content_height{
    height: 85%;
    overflow-y: auto;
    margin-bottom: 20px;
}
.content_height label {
  margin: 12px 0 6px;
}
.content_height label:not([hidden]) {
  display: inline-block;
}

.content_height ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
}

.content_height::-webkit-scrollbar {
    width: 2px;
    background-color: white;
}

.content_height::-webkit-scrollbar-thumb {
    background-color: black;
}

.ml8 {
    margin-left: 8px;
}


.RowBtn{
    margin-left: -45px;
    display: flex;
}

.RowBtn2{
    margin-left: -75px;
    display: flex;
}



.eyeicon{
    margin: 0 10px;
}