@keyframes scroll {
    from {
        transform: translateX(85%);
   }
    to {
        transform: translateX(-85%);
   }
}

.logoWall {
    display: flex;
    justify-content: center;
    background-color: #DB7940;
    color: white;
    padding: 5px;
    margin: auto;
    margin-bottom: 1rem;
    /* width: 80%; */
}
.logoWall .logoWrapper {
    display: flex;
    animation: scroll 40s linear infinite;
    /* width: 100%; */
}
/* 
.logoWall .logoWrapper:nth-child(2) {
    animation: scroll2 40s linear infinite;
    animation-delay: -100s; 
}*/
/* .logoWall .logoWrapper.first > .logo:first-child {
    background: blue;
}
.logoWall .logoWrapper.second > .logo:first-child {
    background: red;
} */
.logoWall .logoWrapper .logo {
    /* width: 150px;
    height: 150px;
    margin: 0 10px;
    border-radius: 50%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center; */
}
