@import '/src/assets/color.css';

.container {
    display: flex;
    flex-direction: column;

}

.gridRowErrorMsg {
    grid-column: 1 / span 3;
}

.displayflex{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.button_on_right{
    display: flex;
    justify-content: flex-end;
    margin: 15px 0 0;
}

.button_on_right button{
    margin: 0 8px;
    width: 30%;
}


.label_style{
    width: 40%;
    font-weight: bold;
}

.input_style,
.input_style1,
.input_style2,
.input_style3,
.input_style4,
.input_style5,
.input_style6,
.input_style7
{
    width: 100%;
}

.input_style1,
.input_style2,
.input_style3,
.input_style4, 
.input_style5,
.input_style6,
.input_style7 {
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 20px;
    color: darkgray;
    cursor: pointer;
    align-items: center;
}

.input_style1{
    font-size: 17px;
    width: 100%;
}
.input_style2{
display: grid;
grid-template-columns: 30% 30% 30%;
justify-items: start;
}

.input_style6{
    display: grid;
    grid-template-columns: 30% 30%;
    justify-items: start;
}
.input_style2 div:hover ,
.input_style6 div:hover
{
    color: var(--color-orange);
}

.input_style1 input{
    accent-color: var(--color-orange-reduce15);
}

.input_style1 input{
 width: 100%;
}

.btn_num{
    width: 50%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-items: center;
}


.content_height{
    height: 85%;
    overflow-y: auto;
    margin-bottom: 20px;
}
.content_height label {
  margin: 12px 0 6px;
}
.content_height label:not([hidden]) {
  display: inline-block;
}

.content_height ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
}

.content_height::-webkit-scrollbar {
    width: 2px;
    background-color: white;
}

.content_height::-webkit-scrollbar-thumb {
    background-color: black;
}

.ml8 {
    margin-left: 8px;
}


.RowBtn{
    margin-left: -45px;
    display: flex;
}

.RowBtn2{
    margin-left: -75px;
    display: flex;
}



.eyeicon{
    margin: 0 10px;
}



.pingfong {
    background-image: url(../../../assets/setting/pingfong_n.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.pingfong:hover{
    background-image: url(../../../assets/setting/pingfong_s.svg);
}
.pingfong img { 
    visibility: hidden;
}

.song {
    background-image: url(../../../assets/setting/song_n.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.song:hover{
    background-image: url(../../../assets/setting/song_s.svg);
}
.song img { 
    visibility: hidden;
}

.hei {
    background-image: url(../../../assets/setting/hei_n.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.hei:hover{
    background-image: url(../../../assets/setting/hei_s.svg);
}
.hei img { 
    visibility: hidden;
}


.styleKerningP,
.styleKerningN,
.styleKerningC,
.styleLineP,
.styleLineN,
.styleLineC,
.styleMarginP,
.styleMarginN,
.styleMarginC,
.stylecolumn,
.stylerow {
    display: flex;
    align-items: center;
    width: 30%;
}


.styleKerningP:hover,
.styleKerningN:hover,
.styleKerningC:hover,
.styleLineP:hover,
.styleLineN:hover,
.styleLineC:hover,
.styleMarginP:hover,
.styleMarginN:hover,
.styleMarginC:hover,
.stylecolumn:hover,
.stylerow:hover {
    color: var(--color-orange); 
}

.packed_w_n {
    background-image: url(../../../assets/setting/packed_w_n.svg); 
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.styleKerningP:hover .packed_w_n{
    background-image: url(../../../assets/setting/packed_w_s.svg);
}
.packed_w_n img { 
     visibility: hidden; 
} 

.normal_w_n {
    background-image: url(../../../assets/setting/packed_w_n.svg); 
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.styleKerningN:hover .normal_w_n{
   background-image: url(../../../assets/setting/packed_w_s.svg);
}
.normal_w_n img { 
    visibility: hidden; 
} 


.comfortable_w_n {
    background-image: url(../../../assets/setting/packed_w_n.svg); 
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.styleKerningC:hover .comfortable_w_n{
   background-image: url(../../../assets/setting/packed_w_s.svg);
}
.comfortable_w_n img { 
    visibility: hidden; 
} 


.packed_l_s {
    background-image: url(../../../assets/setting/packed_l_n.svg); 
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.styleLineP:hover .packed_l_s{
   background-image: url(../../../assets/setting/packed_l_s.svg);
}
.packed_l_s img { 
    visibility: hidden; 
} 


.normal_l_n {
    background-image: url(../../../assets/setting/normal_l_n.svg); 
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.styleLineN:hover .normal_l_n{
   background-image: url(../../../assets/setting/normal_l_s.svg);
}
.normal_l_n img { 
    visibility: hidden; 
} 


.comfortable_l_n {
    background-image: url(../../../assets/setting/comfortable_l_n.svg); 
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.styleLineC:hover .comfortable_l_n{
   background-image: url(../../../assets/setting/comfortable_l_s.svg);
}
.comfortable_l_n img { 
    visibility: hidden; 
} 


.packed_p_n {
    background-image: url(../../../assets/setting/packed_p_n.svg); 
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.styleMarginP:hover .packed_p_n{
   background-image: url(../../../assets/setting/packed_p_s.svg);
}
.packed_p_n img { 
    visibility: hidden; 
} 


.normal_p_n {
    background-image: url(../../../assets/setting/normal_p_n.svg); 
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.styleMarginN:hover .normal_p_n{
   background-image: url(../../../assets/setting/normal_p_s.svg);
}
.normal_p_n img { 
    visibility: hidden; 
} 


.comfortable_p_n {
    background-image: url(../../../assets/setting/comfortable_p_n.svg); 
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.styleMarginC:hover .comfortable_p_n{
   background-image: url(../../../assets/setting/comfortable_p_s.svg);
}
.comfortable_p_n img { 
    visibility: hidden; 
} 


.vert_n {
    background-image: url(../../../assets/setting/vert_n.svg);
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.stylecolumn:hover .vert_n{
   background-image: url(../../../assets/setting/vert_s.svg);
}
.vert_n img { 
    visibility: hidden; 
} 



.horizon_n {
    background-image: url(../../../assets/setting/horizon_n.svg); 
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.stylerow:hover .horizon_n{
   background-image: url(../../../assets/setting/horizon_s.svg);
}
.horizon_n img { 
    visibility: hidden; 
}  




@media screen and (max-width : 520px) {

.styleKerningP,
.styleKerningN,
.styleKerningC,
.styleLineP,
.styleLineN,
.styleLineC,
.styleMarginP,
.styleMarginN,
.styleMarginC,
.stylecolumn,
.stylerow {
    font-size: 15px;
}


.packed_w_n,
.normal_w_n,
.comfortable_w_n,
.packed_l_s,
.normal_l_n,
.comfortable_l_n,
.packed_p_n,
.normal_p_n,
.comfortable_p_n,
.vert_n,
.horizon_n
{
    width: 25%;

}


.displayflex{
    flex-direction: column;
}


.input_style6{
    grid-template-columns: 50% 50%;
}


.input_style1,
.input_style2,
.input_style3,
.input_style4, 
.input_style5,
.input_style6,
.input_style7 {
    justify-content: center;
}

.button_on_right button{
    font-size: 13px;
}
}