/* @import '/src/assets/color.css'; */

.inputPrimary input{
    width: 100%;
    padding: 10px 20px 10px 20px;
    min-width: 100px;
    min-height: 45px;
    outline: none;
    border: none;
    border-radius: 25px;
    background-color: var(--color-black-add90);
}

.inputSecond {
    /*width: 100%;*/
    padding: 8px;
    min-width: 100px;
    min-height: 45px;
    outline: none;
    border: none;
    border-radius: 8px;
    background-color: var(--color--white-reduce15);
}

.inputThird {
    width: 100%;
    border: none;
    border-radius: 4px;
    background: transparent;
}
.inputF {
    width: auto;
    border: none;
    border-radius: 4px;
    background: transparent;
}



.inputFifth {
    width: 100%;
    padding: 10px 20px 10px 20px;
    min-width: 100px;
    min-height: 45px;
    outline: none;
    border: none;
    border-radius: 25px;
    background-color:var(--color-black-add90);
    padding-right: 51px;
}

.inputSelectFile {
    width: 100%;
    min-width: 100px;
    min-height: 45px;
    padding: 8px;
    background-color: var(--color-black);
    border-radius: 4px;
    color: #fff;
    border: none;
    outline: none;
}

.customBadge {
    width:auto;
    position:absolute;
    margin-top: -15px;
    color:var(--color-black-add30);
    text-align:left;
    margin-left: 16px;
    padding-top:4px;
}

.errorMsg {
    color: red;
    
}
.searchInput{
    position: relative;
}
.searchInput > button{
    position: absolute;
    left:85%;
    height:100%;
    top:55%;
    transform: translate(-50%,-50%); 
    
}
.searchInput > button > img{
    height:70%
}


.searchInput input{
    border: 0;
    border-radius: 50px;
    height:100%;
    padding: 0 40px 0 1em;
    font-size: .7rem;
}

.loginInput{
    position: relative;
}


.checkbox {
    /* font-size: 11px; */
    display: flex;
    align-items: center;
  }

input[type='checkbox']{
    appearance: none;
    cursor: pointer;
    /*opacity: 0.5;
    position: absolute;
     background-color: rgb(198,198,198	) */

    appearance: auto;
}
/* input[type='checkbox']:checked + label::before{

    color:white;
    content: '\002714';
    display:flex;
    justify-content:center;
    align-items:center;
} */

/* input[type='checkbox']:focus + label::before{
    box-shadow: 0 0 5px black;
} */

.checkbox label{
    display: flex;
    font-size: 10px;
    justify-content: center;
    color:rgb(198,198,198	);
    /* font-size:20px */
}

.checkbox label::before{
    content:'';
    width: 1em;
    height: 1em;
    background-color: rgb(198,198,198	);
    border-radius:.15em;
    margin-right: 0.5em;
    margin-top: .1em;
    /* border: 1px solid black; */
}
/* .checkbox label:hover::before{
    background-color: black
} */

/* 
.loginInput{
    border: 1px solid rgb(230,230,230);
    border-radius: 50px;
    width: 100%;
    max-height: 30px;
    font-size:12px;
    padding: 5%
} */

.loginInput input {
    width: 100%;
    padding: 10px 20px 10px 20px;
    min-width: 100px;
    min-height: 45px;
    outline: none;
    border: none;
    border-radius: 8px;
    background-color: var(--color-black-add90);
}

.commentInput input{
    width:100%;
    border-radius: 20px;
    border: 1px solid rgb(230,230,230);
    background-color:#FAFAFA;
    /* height:80px; */
    /* vertical-align:text-top; */
    padding: 0px 12px;
}

.textarea input{
    width:100%;
    border-radius: 20px;
    border: 1px solid rgb(230,230,230);
    background-color:#FAFAFA;
    height:80px
}

