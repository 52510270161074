@import '/src/assets/color.css';

.btnPrimary {
    min-width: 120px;
    min-height: 45px;
    padding: 8px;
    background-color: #303030;
    border-radius: 4px;
    color: #fff;
    border: none;
    outline: none;
}

.btnPrimary:disabled {
    min-width: 120px;
    min-height: 45px;
    padding: 8px;
    background-color: #B3B3B3;
    border-radius: 4px;
    color: #fff;
    border: none;
    outline: none;
    margin: 0px 5px;
}

.btnSecond {
    min-width: 120px;
    min-height: 45px;
    padding: 8px;
    background-color: #FFFFFF;
    border-radius: 4px;
    color: #000;
    border: 1px soild black;
    outline: none;
    margin: 0px 5px;
}

.btnSecond:disabled {
    min-width: 120px;
    min-height: 45px;
    padding: 8px;
    background-color: #B3B3B3;
    border-radius: 4px;
    color: #000;
    border: 1px soild black;
    outline: none;
}

.btnThird {
    min-width: 120px;
    min-height: 45px;
    padding: 8px;
    background-color: #995D3D;
    border-radius: 4px;
    color: #fff;
    border: none;
    outline: none;
}

.btnImg {
    border: none;
    background-color: transparent;
    height: 30px;
    width: 30px;
}

.searchButtom{
    /* flex: 1;
    border: none;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: rgb(20,61,72);
    color: white;
    display: flex;
    justify-content:center;
    align-items:center; */
    min-width: 120px;
    min-height: 45px;
    padding: 8px;
    background-color:var(--color-organge-main);
    background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
    border-radius: 25px;
    color: #fff;
    border: none;
    outline: none;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 1.8vh;
    font-weight: bold;
    white-space: nowrap;
    margin: 0 auto;
    cursor: pointer;
}

.upperNavbarButton {
    border:none;
    background-color: transparent;
    height:100%;
    /* background-repeat: no-repeat; */
    cursor: pointer;
    /* display: flex; */
    /* justify-content:center; */
}

.upperNavbarButton:disabled {
    background: #B3B3B3;
}

.lowerNavbarButton{
    border:none;
    color: rgb(20,61,72);
    background-color: transparent;
    /* background-repeat: no-repeat; */
    cursor: pointer;
    /* display: flex; */
    /* justify-content:center; */
}

.upperSideNavButton{
    border:none;
    background-color: transparent;
    cursor: pointer;
    color: rgb(65,152,122);
}

.lowerSideNavButton{
    border:none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    height:100%
}

.buttonGroupButton{
    display: flex;
    background-color:transparent;
    border:2px solid rgb(100,170,148);
    border-radius: 50px;
    font-size:2vw !important;
    align-items: center;
    /* padding: 15px 30px; */
    align-items: center;
    color: rgb(100,170,148);
    flex: 1;
    /* flex-wrap: wrap; */
    /* margin: 10px; */
    min-width: 100px;
    width: 280px;
    min-height:50px
    /* max-height:10vh; */
    /* min-height: 10vh; */
    /* justify-content:space-around */
}


.buttonGroupButton svg{
    /* text-align: center; */
    /* width: 100%; */
    flex:1;
 }

.buttonGroupButton div{
   text-align: start;
   /* width: 100%; */
   flex:2;
}



.buttonGroupButton:hover{
    /* display: flex; */
    background-color: rgb(100,170,148);
    /* border:2px solid rgb(100,170,148); */
    /* border-radius: 50px; */
    /* font-size: 30px; */
    /* padding: 15px 30px; */
    /* align-items: center; */
    color: white;
    flex: 1;
    /* margin: 10px; */
}

.cardButton{
    border: none;
    background-color: transparent;
    /* border-radius:15px;
    min-width:40px;
    min-height:40px;
    color: white; */
}
/* 
.cardButton:hover{
    background-color: white;
    color: var(--color-organge-main); 
    border: 1px solid var(--color-organge-main); ;
} */

.toggleButton{
    border: none;
    background-color: transparent;
    position: relative;
    cursor: pointer;
}

.toggleButtonCurrent{
    border: none;
    background-color: transparent;
    position: relative;
    color: rgba(181 60 63);
    cursor: pointer;
}

.toggleButtonCurrent::after{
    content:"";
    position:absolute;
    bottom:-70%;
    left:0;
    width:100%;
    height:3px;
    background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
}

.toggleButton::after{
    content:"";
    position:absolute;
    bottom:-70%;
    left:0;
    width:100%;
    height:3px;
    background-color:rgb(217,217,217)
}

.MainButton{
    border: 1px solid var(--color-organge-main) ;
    color:white;
    font-size:15px;
    padding:3%;
    background-color:var(--color-organge-main);
    background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
    border-radius:50px;
    width: 100%;
    width: 25%;
    margin: 0 auto;
    font-weight: bold;
    cursor: pointer;
    min-width: 120px;
    min-height: 45px;
}
/* .MainButton:hover{
    border: 1px solid var(--color-organge-main) ;
    color:var(--color-organge-main);;
    font-size:15px;
    padding:3%;
    background-color:white;
    border-radius:50px;
     font-weight: bold;
} */

.SecondButton{
    border: 1px solid var(--color-organge-main) ;
    color:white;
    font-size:15px;
    padding:3%;
    background-color:var(--color-organge-main);
    background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
    border-radius:50px;
    width: 100%;
    width: 45%;
    margin: 0 auto;
    font-weight: bold;
    cursor: pointer;
    min-width: 120px;
    min-height: 45px;
}


.ThirdButton{
    /* border: 1px solid var(--color-organge-main) ; */
    color:var(--color-organge-main);
    font-size:15px;
    padding:3%;
    background-color:var(--color--white--reduce);
    
    border-radius:50px;
    width: 100%;
    width: 45%;
    margin: 0 auto;
    border: 0;
    font-weight: bold;
    cursor: pointer;
}


.forgetButton{
    color:var(--color-organge-main);
    border: none;
    background-color:transparent;
    font-size: 10px;
}   
.googleButton{
    background-color:white;
    border: none;
    display: flex;
    justify-content:center;
    align-items:center;
    color: rgb(198	198	198	);
    padding: 3%;
    border-radius:50px;
    /* z-index: 0; */
}

.facebookButton{
    display: flex;
    justify-content:center;
    align-items:center;
    padding: 3%;
    border-radius:50px;
    border: none;
    background-color:rgb(32 92 173);
    color:white;
    
}

.tabButtonCurrent{
    border-top-left-radius: 10px;
    border-top-right-radius: 27px 60px;
    background-color: transparent;
    border: none;
    color: rgb(0 169	65	)	;
    /* padding:10px 20px; */
    /* border-left: 10px solid transparent; */
    /* padding-top: 10px; */
    border-right: 10px solid transparent;
    border-bottom: 35px solid  rgb(255	255	237);
    height:35px;
    flex:1;
    position: relative;
}

/* 243	243	243	 */

.tabButton{
    border-top-left-radius: 10px;
    border-top-right-radius: 27px 60px;
    background-color: transparent;
    border: none;
    color: rgb(198	198	198	);
    /* padding:10px 20px; */
    /* border-left: 10px solid transparent; */
    /* padding-top: 10px; */
    border-right: 10px solid transparent;
    border-bottom: 35px solid  rgb(243,243,243);
    height:35px;
    flex:1;
    position: relative;
}

.verifyButton{
    position: absolute;
    right:0;
    height:100%;
    border-radius:50px;
    border:none;
    font-size:0.6em;
    color: white;
    border: 1px solid var(--color-organge-main);
    background-color:var(--color-organge-main);
    padding: 0 1.2em 0 1.2em;
}

.bookIntroButton{
    border: 1px solid var(--color-organge-main) ;
    color:white;
    background-color:var(--color-organge-main);
    border-radius:50px;
    width: 100%;
}


.btnImg {
    border: none;
    background-color: transparent;
    height: 30px;
    width: 30px;
}


.cancelButton{
    color: rgba(181 60 63);
    background: var(--color-orange-add70);
    width: 100%;
    border-radius: 50px;
    height: 35px;
    /* font-size: 0.7em; */
    max-width: 90px;
    border: 0;
    cursor: pointer;
}

.submitButton{
    color: white;
    background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
    width: 100%;
    border-radius: 50px;
    height: 35px;
    /* font-size: 0.7em; */
    max-width: 90px;
    margin: 0 3px;
    border: 0;
    cursor: pointer;
}