
.Header {
  display: flex;
  justify-content: space-between;
}

.backHomeButtonContainer{
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.backHomeButtonContainer  img {
  /* height: 15px; */
  margin: 0 1em 0 0;
}
.backHomeButtonContainer  button {
  color: rgba(181 60 63);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}


.pageNav {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.pageNav * {
  font-size: 0.7rem;
}

.toggleGroup button{
  width: 33%;
  font-size: 18px;
  font-weight: bold;
}




.table td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table th {
  color: var(--color-red-main);
  background-color: var(--color-orange-add70);
  align-items: center;
}

.toggleGroupContainer{
  padding: 1em 0;
}

.counterReport {
  background-color: #faf9f7;
  padding: 1em;
  margin: 50px 0 2em 0;
}

.counterReport h2 {
  font-size: 2rem;
}

.counterReport button{
margin: 0 10px;
}

.counterReport p{
  font-size: 14px;
  margin: 1em 0 1em 0;
}

.counterReport label {
  font-size: 14px;
}
.selectGroupContainer {
  display: flex;
  margin: 1.5em 0 1em 0;
}

.selectGroupContainer div{
  margin-right: 5px;
}

.line {
  margin: 1em 0 1em 0;
}

.datePickerContainer {
  margin: 0 0 1em 0;
}

.textarea {
  height: 100px;
  width: 300px;
  resize: none;
  margin: 0 0 1em 0;
}

.userName {
  width: 300px;
  margin: 0 0 1em 0;
}
