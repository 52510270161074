.button_on_right{
    /* display: flex;
    position: absolute;
    justify-content: flex-end;
    bottom: 15px;
    right: 35px; */

    display: flex;
    justify-content: flex-end;
    margin: 15px 0 0;
}

.button_on_right button{
    margin: 0 8px;
    width: 30%;
}


.container{
  padding: 10px 10px 25px;
}



.content_height{
    height: 85%;
    overflow-y: auto;
}
.content_height label {
  margin: 12px 0 6px;
}
.content_height label:not([hidden]) {
  display: inline-block;
}

.content_height ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  .content_height::-webkit-scrollbar {
    width: 2px;
    background-color: white;
  }

  .content_height::-webkit-scrollbar-thumb {
    background-color: black;
  }