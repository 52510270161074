.setting-panel-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 44px);
  height: 38px;
  font-weight: 500;
  margin: 4px 22px;
}
.setting-panel-parent {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 299px;
  height: 100%;
  overflow: hidden;
  animation: fade-right 0.2s ease-in-out 0s 1;
  z-index: 10;
  opacity: 1;
}

.setting-panel {
  opacity: 1;
  width: 100%;
  height: calc(100% - 55px);
  overflow-y: scroll;
  box-sizing: border-box;

  /* display: none; */
}
.single-control-switch-container {
  margin: 15px 20px 10px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.single-control-switch-title {
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
}
.single-control-switch {
  display: inline-block;
  width: 40px;
  height: 20px;
  opacity: 1;
  border-radius: 10px;
  position: relative;
  top: 3px;
  left: 2px;
  cursor: pointer;
}
.single-control-switch.off {
  background-color: lightgray !important;
}
.single-control-button {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin: 2px;
}
.lock-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 20px;
  opacity: 0.3;
  margin: 10px;
  cursor: pointer;
  font-size: 20px;
}
.pages_str {
  margin: 10px;
}