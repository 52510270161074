@import '/src/assets/color.css';

.TableCardViewContainer {
    min-width: 200px;
    max-width: 360px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    border-radius: 8px;
    max-height: 330px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.TableCardViewContainer2{
    min-width: 200px;
    transition: 0.3s;
    border-radius: 8px;
    /* max-height: 330px; */
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.TableCardView_IMG {
    display: flex;
    justify-content: center;
    margin: 0 8px 0;
    padding: 8px;
    align-items: center;

}

.TableCardView_IMG  img{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    max-width: 140px;
    /* object-fit: contain; */
    /* height:90%; */
    max-height:190px;
}

.TableCardViewContent>div {
     margin: 4px;
     word-break: break-word;
}

.TableCardViewContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 8px;
    justify-content: center;
    max-width: 280px;
}

.TableCardViewContent h5{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.TableCardViewContainer ::-webkit-scrollbar-track, .TableCardViewContent ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  .TableCardViewContainer::-webkit-scrollbar, .TableCardViewContent::-webkit-scrollbar {
    width: 2px;
    background-color: #de663c;
  }

  .TableCardViewContainer::-webkit-scrollbar-thumb , .TableCardViewContent::-webkit-scrollbar-thumb{
    background-color: black;
  }


.butoonGroup{
    display: flex;
    align-items: center;
    justify-content: center;
}


.butoonGroup button{
margin: 0 5px;
width: 30%;
}


.content{
    margin-top: 9px;
    font-size: 14px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
}

@media screen and (max-width : 726px) {
    .content{
        display: inline-block;
    }

    .TableCardViewContent {
        max-width: 500px;
    }
}