.layout {
  margin: 2em 0 0 0;
  display: flex;
  
  display: flex;
  position: relative;
  padding: 16px;
  padding: 30px;
  /* border: 1px solid #C1C1C1; */
  border-radius: 8px;
  flex-flow: row wrap;
  font-size: 18px;
}
.button > div {
  margin: 1em 2em 2em 2em;
}
.button h4 {
  text-align: center;
}
.button {
  box-shadow: 1px -1px 5px 1px rgb(235 235 235);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1em 0 0;
  cursor: pointer;
}



.button img {
  min-width: 150px;
}



@media screen and (max-width:480px) {
  .layout {
    justify-content: center;
  }

  .button {
    margin: 0;
  }
}