.container {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 1rem 0px;
    display: grid;
    grid-template-columns: 1fr 3fr 7fr 7fr 7fr 10fr;
    align-items: center;
    justify-items: center;
    grid-gap: 8px;
}

.img_view {
    max-height: 130px;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.functionGroup {
    display: flex;
    width: 75%;
}