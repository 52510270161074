
.megamenu{
    position: absolute;
    /* top: 110px; */
    color: #000;
    z-index: 1000;
    margin: 0px;
     margin-left: 10%; 
    left: 0;
    text-align: left;
    padding: 48px;
    font-size: 15px;
    /* border-bottom: solid 3px #b9b9b9; */
    background-color: #fff;
    /* opacity: 0; */
    border-radius: 0 0 5px 5px;
    box-shadow: 5px 5px 37px 0px rgb(235 235 235);
    -o-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -webkit-transform-origin: 0% 0%;
    -o-transition: -o-transform 0.3s, opacity 0.3s;
    -ms-transition: -ms-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    width: 80%;
    display: none;
    overflow-y: auto;
    height: 75vh;
}

.megamenu .title {
    font-size: 14px;
    padding: 4px 5px 5px 0px;
    /* font-size: 1em; */
    color: #424242;
    margin-bottom: 7px;
    text-align: left;
    height: 2em;
    line-height: 28px;
}

.megamenu .title> a{
    /* border-top: 3px solid;
    margin: 0 8px; */
    color: var(--color-black-main);
    font-weight: 900;
    padding: 4px 8px 9px;
    width: fit-content;
}

.borderLine{
    border-top: 2px solid;
    margin: 0 8px;
    color: var(--color-red-main);
    width: 8%;
}



.col{
    position: relative;
    min-height: 70px;
    padding-right: 3px;
    padding-left: 6px;
    display: inline-block;
    width: 20%;
    vertical-align: top;
}

.col li {
    display: block;
    text-align: center;
    white-space: pre-wrap;
    text-align: left;
}

.col li a {
    font-size: 14px;
    line-height: 15px;
    border-right: none;
    text-align: left;
    padding: 6px 0px;
    padding-left: 16px;
    padding-left: 8px;
    /* background: #fff !important; */
    background-image: none !important;
    color: var(--color-black-add30);
    border-right: 0 none !important;
    display: block;
    border-right: 1px solid #e7e7e7;

}

.chevron_right{
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 0.55em;
    left: 0.15em;
    position: relative;
    top: 0;
    transform: rotate(45deg);
    vertical-align: top;
    width: 0.55em;
    color: var(--color-black-add30);
}

.lirow{
    display: flex !important;
    align-items: center;
    padding-left: 8px;
}