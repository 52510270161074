@import '/src/assets/color.css';

.mainSelect select {
  outline: 0;
  border: 0;
  box-shadow: none;
  flex: 1;
  padding: 0 1em;
  color: #000;
  background-color: var(--color--white-reduce15);
  cursor: pointer;
  border-radius: 1.25em;
  border-radius: 10px;
  border-radius: 25px;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
}

.mainSelect select::-ms-expand {
  display: none;
}

.mainSelect {
  position: relative;
  display: flex;
  width: 15em;
  height: 3em;
  border-radius: 1.25em;
  border-radius: 10px;
  border-radius: 25px;
  /* overflow: hidden; */
  flex: 0 0 15em;
  /* padding: 0 10px 0;
  flex-direction: column; */
}

.mainSelect option{
  background-color: #fff;
}

.mainSelect::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: var(--color--white-reduce15);
  transition: .25s all ease;
  pointer-events: none;
  color: var(--color-blue-add25);
  border-radius: 25px;
  height: 1em;
}

.mainSelect:hover::after {
  color: var(--color-blue-add25);
}

.overflow{
  overflow: hidden;
  width: 100%;
  height: 100%;
}


.secondSelect select {
  outline: 0;
  border: 0;
  box-shadow: none;
  flex: 1;
  padding: 0 1em;
  color: #000;
  background-color: var(--color-black-add90);
  cursor: pointer;

  -webkit-appearance: none;
  border-radius: 25px;
  width: 100%;
  height: 100%;
}

.secondSelect select::-ms-expand {
  display: none;
}

.secondSelect {
  position: relative;
  display: flex;
  width: 100%;
  width: 15em;
  height: 3em;
  border-radius: 1.25em;
  border-radius: 25px;
 /* overflow: hidden;*/

}

.secondSelect option{
  background-color: #fff;
}

.secondSelect::after {
  content: '\25BC';
  content: '\2335';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: var(--color-black-add90);
  transition: .25s all ease;
  pointer-events: none;
  color: var(--color-orange);
  border-radius: 25px;
  height: 1em;
  font-weight: bold;
}


.secondSelect:hover::after {
  color: var(--color-blue-add25);
  color: var(--color-orange);
}








.thirdSelect select {
  outline: 0;
  border: 0;
  box-shadow: none;
  flex: 1;
  padding: 0 1em;
  color: #000;
  background-color: var(--color-black-add90);
  cursor: pointer;
  border-radius: 25px;
  -webkit-appearance: none;
}

.thirdSelect select::-ms-expand {
  display: none;
}

.thirdSelect {
  position: relative;
  display: flex;
  width: 100%;
  height: 2em;
  border-radius: 1.25em;
  border-radius: 25px;
  /* overflow: hidden; */
  margin: 0 1px;
}

.thirdSelect option{
  background-color: #fff;
}

.thirdSelect::after {
  content: '\25BC';
  position: relative;
  top: 0;
  right: 0;
  padding: 0.5em;
  background-color: var(--color-black-add90);
  transition: .25s all ease;
  pointer-events: none;
  color: var(--color-blue-add25);
  display: none;
}


.thirdSelect:hover::after {
  color: var(--color-blue-add25);
}






.fourSelect select {
  outline: 0;
  border: 0;
  box-shadow: none;
  flex: 1;
  padding: 0 1em;
  color: #000;
  background-color: var(--color-black-add90);
  cursor: pointer;

  -webkit-appearance: none;
}

.fourSelect select::-ms-expand {
  display: none;
}

.fourSelect {
  position: relative;
  display: flex;
  width: 100%;
  height: 2em;
  border-radius: 1.25em;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 1px;
}

.fourSelect option{
  background-color: #fff;
}

.fourSelect::after {
  content: '\25BC';
  position: relative;
  top: 0;
  right: 0;
  padding: 0.5em;
  background-color: var(--color-black-add90);
  transition: .25s all ease;
  pointer-events: none;
  color: var(--color-blue-add25);
  display: none;
}


.fourSelect:hover::after {
  color: var(--color-blue-add25);
}

.customBadge {
  width:auto;
  position:absolute;
  margin-top: -18px;
  color:var(--color-black-add30);
  text-align:left;
  margin-left: 8px;
  padding-top: 3px;
  font-size: 13px;
}


.fiveSelect select {
  outline: 0;
  border: 0;
  box-shadow: none;
  flex: 1;
  padding: 0 1em;
  color: #000;
  background-color: var(--color-black-add90);
  cursor: pointer;
  border-radius: 25px;
  -webkit-appearance: none;
}

.fiveSelect select::-ms-expand {
  display: none;
}

.fiveSelect {
  position: relative;
  display: flex;
  width: 100%;
  height: 2em;
  border-radius: 1.25em;
  border-radius: 25px;
  /* overflow: hidden; */
  margin: 0 1px;
}


.fiveSelect option{
  background-color: #fff;
}

.fiveSelect::after {
  content: '\25BC';
  position: relative;
  top: 0;
  right: 0;
  padding: 0.5em;
  background-color: var(--color-black-add90);
  transition: .25s all ease;
  pointer-events: none;
  color: var(--color-blue-add25);
  display: none;
}


.fiveSelect:hover::after {
  color: var(--color-blue-add25);
}


.inputPrimary input{
  width: 100%;
  padding: 10px 20px 10px 20px;
  min-width: 100px;
  min-height: 45px;
  outline: none;
  border: none;
  border-radius: 25px;
  background-color: var(--color-black-add90);
}

#InputKeyword{
  /* border-radius: 25px 0px 0px 25px; */
  /* margin-right: 5px; */
} 

.inputOption select{
  /* border-radius: 0px 25px 25px 0px; */
  margin-left: 5px;
  width: 97%;

}