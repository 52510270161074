.Header {
    display: flex;
    justify-content: space-between;
}
  
  .backHomeButtonContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .backHomeButtonContainer  img {
    /* height: 15px; */
    margin: 0 1em 0 0;
  }
  .backHomeButtonContainer  button {
    color: rgba(181 60 63);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .pageNav {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }

  .pageNav * {
    font-size: 0.7rem;
  }
  
  .ButtonContainer > button {
    color: rgba(181 60 63);
  }

  .ButtonContainer{

  }

  .RecordContainer{
    padding-top: 30px;
  }


  .toggleGroup button{
    width: 50%;
    font-size: 18px;
    font-weight: bold;
  }


  .container h4{
    font-size: 32px;

  }

.header {
  display: grid;
  grid-template-columns: auto  auto;
  padding: 16px 8px 16px;
}


.row input{
  border-radius: 25px;
}

.rowSpan {
  display: grid;
  grid-template-columns:  120px 20px 120px;
  grid-gap: 8px;
  direction: rtl;
  align-items: center;
}

.border_line{
  border-right: 2px solid;
  height: 100%;
  margin: 0 8px;
}


.row{
  white-space: nowrap;
  padding: 0 10px 0 0;
  display: flex;
}

.RowBtn{
  margin-left: -45px;
}

.tableHeader {
  display: grid;
  grid-template-columns:  100px 1fr 100px;
  grid-gap: 16px;
  justify-content: space-between;
  padding-bottom: 8px;
  font-size: 22px;
  align-items: center;
}

.tableHeaderItemCol1 {
    margin-left: 15px;
}


@media screen and (max-width: 560px) {
  
  .header {
     grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  }

  .header>div{
    margin: 10px 0;
    padding: 0;
  }

  .rowSpan {
    justify-content: space-evenly;
  }

  .row>div:nth-child(1){
    width: 100%;
  }
  
}