  .Header {
      display: flex;
      justify-content: space-between;
  }
  
  .backHomeButtonContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .backHomeButtonContainer  img {
    /* height: 15px; */
    margin: 0 1em 0 0;
  }
  .backHomeButtonContainer  button {
    color: rgba(181 60 63);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ContentItemlist{
    display: flex;
    align-items: center;
}

.margin_7px{
    margin: 7px;
}

.title{
  text-align: center;
  margin: 30px;
}

.title h2{
  font-size: 20px;
}

.title h1{
  font-size: 25px;
}

  .ContentItem_container{
    margin: 1em 0 3em 0 ;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
}
  
  .pageContentLayout {
    display: flex;
    margin: 2em 0 0 0;
    flex-direction: column;
    align-items: center;

    display: grid;
    grid-template-columns: auto auto auto;
  }
  
.displayFlex{
  display: flex;
}
.displayFlex button{
  padding: 2%;
  width:35%
}

.selectMargin{
  margin-top: 15px;
}

.selectMarginLong{
  margin-top: 15px;
  flex: 2 !important;
}

.selectOptionsRow{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.selectOptionsRow label{
font-size: 13px;
}

.selectOptionsRow2{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.selectOptionsRow div{
  width: 98%;
  margin: 0 auto;
  flex: 1;
}

.selectOptionsRow2 div{
  width: 100%;

}

.inputtext input{
  min-height: 48px;
  width: 98%;
  margin: 0 auto;
  display: flex;
}

.selectOptionsRow .InputKeyword {
  min-height: 48px;
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex: 3;
}


.selectOptionsRow .inputOption {
  min-height: 48px;
  width: 98%;
  margin: 0 auto;
  display: flex;
}

@media screen and (max-width: 768px) {
  .selectMargin, .selectMarginLong{
    margin-top: 30px;
  }

  .inputOption{
    margin-top: 30px;
  }

  .selectOptionsRow2{
    margin-top: 0;
  }
}