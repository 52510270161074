.Header {
  display: flex;
  justify-content: space-between;
}

.backHomeButtonContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.backHomeButtonContainer img {
  /* height: 15px; */
  margin: 0 1em 0 0;
}

.backHomeButtonContainer button {
  color: rgba(181 60 63);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  margin: 1em 0 0 0;
  flex-direction: column;
}

.textarea {
  resize: none;
  height: 50em;
}

.settingRow {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 1rem 0;
}

.settingRowItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.submitContainer {
  margin-left: auto;
  width: 100px;
}

/*

.container h1{
    font-size: 35px;
    font-weight: bold;
    margin: 10px 0;
    line-height: 2;
}


.button_on_right{
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}

.button_on_right button{
    margin: 0 5px;
    padding : 1.5%;
    width: auto;
} */