@import "/src/assets/color.css";

.Container {
  /* Ken TODO */
  /* border: 1px solid grey; */
  padding: 0.5em 0.5em 0.5em;
  min-width: 200px;
  max-width: 250px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  transition: 0.3s;
  border-radius: 8px;
  /* max-height: 300px; */
  margin: 10px 15px 10px;
}

.CardView_IMG {
  display: flex;
  justify-content: center;
  margin: 0 8px 0;
  padding-top: 8px;
  position: relative;
}

.CardView_IMG img {
  box-shadow: 1px 1px 1px 1px rgb(152 151 151);
}

.CardView_IMG button {
  position: absolute;
  padding: 10px 30px;
  top: 65px;
  width: auto;
}

.Content > div {
  margin: 4px;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  flex-wrap: nowrap;
  width: 200px;
}

.Content h5 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.bottom {
  display: flex;
  flex-direction: column;
}

.processRow {

  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 4px 0;
}
.processRowHide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 4px 0;
  visibility: hidden;
}

.processbarContainer {
  position: relative;
  min-width: 25px;
  height: 14px;
  background-color: #fff5f0;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  /* border: 0.5px solid; */
  /* border-radius: 20px; */
  border: 2px solid  var(--color-orange-add);
  border-radius: 8px;

  align-items: center;
}

.percent {
  margin-left: 10px;
  width: 20%;
  font-size: 10px;
}
.processbar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--color-orange-add);
  z-index: 0;
  transition: width 0.1s;
  border-radius: 20px;
}

.bottom button {
  width: 95%;
  margin: 4px 0;
}

.ContentDate {
  font-size: 10px;
  margin: 10px 0;
}

.scrollingWrapper ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.scrollingWrapper::-webkit-scrollbar {
  width: 2px;
  background-color: white;
}

.scrollingWrapper::-webkit-scrollbar-thumb {
  background-color: black;
}
.buttonContainer button {
  min-width: 40%;
  width: 70px;
  justify-content: center;
  margin: 0 10px 5px 0;
  min-height: 30px;
}
@media screen and (max-width: 768px) {
  .Container {
    margin: 10px 0px 10px;
  }
}


.dialogButtonContainer{
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}


.dialogButtonContainer3{
  display: flex;
  justify-content: center;
  margin-top: 15px;
  align-items: center;
}

/* .dialogButtonContainer button:last-child , .dialogButtonContainer3 button:last-child{
  color: white;
  background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
  width: 100%;
  border-radius: 50px;
  height: 35px;

  max-width: 90px;
  margin: 0 3px;
}

.dialogButtonContainer button:first-child , .dialogButtonContainer3 button:first-child{
  color: rgba(181 60 63);
  background: var(--color-orange-add70);
  width: 100%;
  border-radius: 50px;
  height: 35px;

  max-width: 90px;
} */