.menu-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 158px;
  height: 132px;
  padding-left: 1px;
}
.note-option,
.digest-option,
.translation-option,
.speaker-option,
.search-book-option,
.google-option,
.copy-option {
  height: 42px;
  width: 42px;
  font-size: 14px !important;
  line-height: 15px !important;
  opacity: 1;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.1s;
  /* align-items: center; */
  justify-content: center;
  font-weight: 500;
  margin: 5px;
  margin-bottom: 0px;
  margin-top: 3px;
}

/* hide highlight */
/*
.digest-option {
  visibility: hidden;
}
*/
/* hide highlight end */

.note-option:hover,
.digest-option:hover,
.translation-option:hover,
.speaker-option:hover,
.search-book-option:hover,
.google-option:hover,
.copy-option:hover {
  border-radius: 5px;
}

.note-icon,
.translation-icon,
.speaker-icon,
.search-book-icon,
.google-icon,
.baidu-icon,
.bing-icon,
.copy-icon,
.digest-icon {
  line-height: 45px !important;
  font-size: 27px;
}
.copy-icon {
  color: #ff9900;
}
.digest-icon {
  color: #5e7fff;
}
.note-icon {
  color: #109870;
}
.speaker-icon {
  color: #0063b1;
  font-size: 30px;
  margin-left: 2px;
}
.search-book-icon {
  color: #6867d1;
}
.google-icon {
  color: #00b6c2;
  font-size: 25px;
}
.baidu-icon {
  color: rgb(252, 204, 136);
}
.bing-icon {
  color: rgb(252, 204, 136);
}

.speaker-option {
  width: 142px;
  align-items: center;
}
.speaker-option > div {
  flex: 0 0 auto;
}
.speaker-option > select {
  flex: 1 1 100%;
  width: 110px;
  height: 30px;
}