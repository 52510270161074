@import '/src/assets/color.css';

.tableItemListContainer {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
}


.topRow {
    font-weight: bold;
    padding: 6px;
    background-color:  var(--color-orange-add);;
    border-radius: 10px 10px 0 0;
}


.content{
    height: 100%;
    width: 100%;
    padding: 15px 0;
}

.padding{
    padding: 17px;
}

.padding5px{
    padding: 5px;
}

.title{
    font-weight: bold;
}


.nameArea{
    font-weight: bold;
    padding: 20px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.nameArea h1{
  font-weight: bold;
  font-size: 30px;
  margin: 8px 0;
  margin: 8px 30px 8px 8px;
}

.recordArea{
    font-weight: bold;
}


.recordArea2{
    font-weight: bold;
    padding: 5px;
}

.ClientTitle{
    color: darkgray;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 10px;
    width: 30%;
}


.ClientTitle button{
    min-width: 80px;
    min-height: 30px;
    margin: 5px;
}

.Line{
    border-bottom: 1px solid;
    height: 100%;
    color: darkgray;
}


.recordRow{
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid darkgray;
}

.recordRow div{
 margin-right:5px ;
}


.recordRow2{
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid darkgray;
}
.recordRow2 div{
    margin-right:5px;
    font-size: 15px;
}
   

.recordTopRow{
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
}

.btn{
    cursor: pointer;
    color: var( --color-orange);
    font-size:10px;
}
.actionLogHighlightText{
    color: var( --color-orange);
}


.dialogButtonContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }


@media screen and (max-width:540px) {
    .nameArea{
        flex-direction: column;
        gap: 10px;
    }
    .ClientTitle{
        width: 100%;
    }
}