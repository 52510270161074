.Header {
  display: flex;
  justify-content: space-between;
}

.backHomeButtonContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.backHomeButtonContainer img {
  /* height: 15px; */
  margin: 0 1em 0 0;
}
.backHomeButtonContainer button {
  color: rgba(181 60 63);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageNav {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.pageNav * {
  font-size: 0.7rem;
}

.ButtonContainer > button {
  color: rgba(181 60 63);
}

.ButtonContainer {
}

.PlatformContainer {
  padding-top: 30px;
}

.toggleGroup button {
  width: 50%;
  font-size: 18px;
  font-weight: bold;
}

.container {
  padding: 50px 0 0;
  /*
    display: flex;
    flex-direction: column;
    height: calc(100vh - 45px);
    */
}

.container h4 {
  font-size: 20px;
}

.header {
  display: flex;
  /* grid-template-columns: auto; */
  padding: 16px 8px 16px;
}

.row input {
  border-radius: 25px;
}

.rowSpancontainer {
  display: grid;
  /* grid-template-columns: minmax(100px, 1fr) repeat(5, auto); */
  gap: 16px;
  grid-template-columns: repeat(auto-fit, 120px);
  flex: 1 1 auto;
  justify-content: end;
}

.rowSpan {
  display: grid;
  grid-template-columns: 120px 120px 120px;
  grid-gap: 8px;
  direction: rtl;
  align-items: center;
  max-width: 275px;
}

.border_line {
  border-right: 2px solid;
  height: 100%;
  margin: 0 8px;
}

.row {
  white-space: nowrap;
  padding: 0 10px 0 0;
  display: flex;
}

.RowBtn {
  margin-left: -45px;
}

.tableHeader {
  display: grid;
  grid-template-columns: 2fr 5fr 1fr;
  grid-template-columns: 8fr 1fr;
  grid-gap: 16px;
  justify-content: space-between;
  padding-bottom: 8px;
  font-size: 22px;
  align-items: center;
}


.tableHeader2{
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  width: 100%;
  align-items: end;
}

.tableHeaderItemCol1 {
  padding-left: 8px;
}

.dialogButtonContainer {
  display: flex;
  justify-content: flex-end;
}
.dialogButtonContainer button:last-child {
  color: white;
  background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
  width: 50px;
  border-radius: 50px;
  height: 25px;
  font-size: 0.7em;
}
.dialogButtonContainer button:first-child {
  color: rgba(181 60 63);
  background: pink;
  width: 50px;
  border-radius: 50px;
  height: 25px;
  font-size: 0.7em;
}


@media screen and (max-width: 1024px) {
    .header {
      flex-direction: column;
      gap: 15px;
    }

    .rowSpancontainer {
      justify-content: initial;
    }
}
@media screen and (max-width: 560px) {
  .header {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .header > div {
    margin: 10px 0;
    padding: 0;
    grid-template-columns: minmax(100px, 1fr) repeat(1, auto);
  }

  .rowSpan {
    justify-content: space-evenly;
  }

  .row > div:nth-child(1) {
    width: 100%;
  }
}
