.Container {
    min-width: 200px;
    max-width: 250px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    border-radius: 8px;
    /* max-height: 300px; */
    margin: 10px 7px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CardView_IMG {
    display: flex;
    justify-content: center;
    margin: 0 8px 0;
    padding-top: 8px;
    position: relative;
   
}


.CardView_IMG img{
box-shadow: 1px 1px 1px 1px rgb(152 151 151);
}

.CardView_IMG button{
    position: absolute;
    padding: 10px 30px;
    top: 65px;
}


.Content>div {

    margin: 4px;
   
}

.Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    flex-wrap: nowrap;
    max-width: 230px;
}

.Content h5{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.Container button {
    background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
    border-radius: 50px;
    font-weight: bold;
    color: white;
    font-size: 15px;
    padding: 3%;
    width: 100%;

}


.ContentDate{
    font-size: 10px;
    margin: 15px 0 0 4px  !important;
}

.Container ::-webkit-scrollbar-track, .TableCardViewContent ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  .Container::-webkit-scrollbar, .TableCardViewContent::-webkit-scrollbar {
    width: 2px;
    background-color: white;
  }

  .Container::-webkit-scrollbar-thumb , .TableCardViewContent::-webkit-scrollbar-thumb{
    background-color: black;
  }


  @media screen and (max-width: 768px){
    .Container{
        margin: 10px 0px 10px;
    }
}