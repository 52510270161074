.main {
    height: 95%;
    width: 100%;
    /* background-color: aqua; */
}

.list {
    overflow: scroll;
    height: 90%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.dialogButtonContainer {
    height: 10%;
    padding: 1rem;
    text-align: end;
}

.dialogButtonContainer button:last-child {
    color: white;
    background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
    width: 10rem;
    border-radius: 50px;
    /* height: 25px; */
    /* font-size: 0.7em; */
}

.dialogButtonContainer button:first-child {
    color: rgba(181 60 63);
    background: pink;
    width: 10rem;
    border-radius: 50px;
    /* height: 25px; */
    /* font-size: 0.7em; */
}

.conflictCardTitle {
    border-bottom: 1px solid #888;
    padding: 1rem;
    background-color: rgba(253 239 232);
    border-radius: 8px 8px 0px 0px;
}

.conflictCard {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin-bottom: 1rem;
    border-radius: 8px;
    border: 1px solid #888;
    background-color: rgba(245 245 245);
}

.conflictItemCard {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem;
    border-radius: 8px;
    border: 1px solid #888;
    padding: 1rem;
    cursor: pointer;
    background-color: rgba(230 230 230);
    /* width: 100%; */
}

.isSelected {
    color: white;
    background: var(--color-orange);
}
.isSelectedTitle {
    /* color: rgba(181 60 63); */
    background: var(--color-orange-add);
}