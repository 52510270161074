@import '/src/assets/color.css';


.background_sidemenu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.sideMenu {
    position: fixed;
    width: 47vw;
    width: 280px;
    height: calc(100vh - 70px - 45px);
    z-index: 2;
    top: calc(70px + 45px);
    left: 0;
    background-color: var( --color--white);
    overflow-x: hidden;
    padding: 80px 0 50px;
    padding: 50px 0 50px;
    overflow-y: auto;
}


  .sideMenu ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  .sideMenu::-webkit-scrollbar {
    width: 2px;
    background-color: white;
  }

  .sideMenu::-webkit-scrollbar-thumb {
    background-color: black;
  }

.sideMenuMobile{
    position: fixed;
    width: 100%;
    height: 95vh;
    z-index: 2;
    bottom: 0;
    left: 0;
    background-color: var( --color--white);
    overflow-x: hidden;
    padding: 80px 0 50px;
    overflow-y: auto;
}

.nav{
    display: flex;
    flex-direction: column;
}

.nav img{
    width: 20px;

}



.nav ul{
    color: black;
    align-items: normal;
    justify-content: normal;
    flex-direction: column;
    padding: 0;
}

.nav ul li{
border-top: 0.5px solid lightgray;
display: flex;
align-items: center;
/* padding: 15px 1em; */
cursor: pointer;
font-size: 0.9rem;
}

.nav ul li button, .nav ul li img{
    
    color: black; 
    margin: 0;
}

.nav ul li a {
    width: 100%;
    line-height: 50px;
    border-left: 20px solid transparent;
}

.nav ul li:nth-last-child(2){
    width: 100%;
    line-height: 50px;
    border-left: 20px solid transparent;
}




.nav ul li:nth-last-child(2) button{
    border-style: solid !important;
    border-width: 15px 220px 15px 0px !important;
    border-color: transparent !important;
}

.nav ul li:last-child{
    margin-bottom: 60px;
}


.nav button{
    font-size: 14.4px;
}

.nav_item_lang{
    width: 100%;
    line-height: 50px;
    border-left: 20px solid transparent;
}


.closeNav{
    display: flex;
    position: absolute;
    left: 30px;
    top: 30px;
    font-weight: bold;
    color: var(--color-orange);
    font-size: 20px;
    cursor: pointer;
}

.closeNav div{
 margin: 0 5px;
}



.arrow_btn{
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
}