.audioBookContainer>* {
    -webkit-touch-callout: none !important;
    /* iOS Safari */
    -webkit-user-select: none !important;
    /* Safari */
    -khtml-user-select: none !important;
    /* Konqueror HTML */
    -moz-user-select: none !important;
    /* Firefox */
    -ms-user-select: none !important;
    /* Internet Explorer/Edge */
    user-select: none !important;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.audioBookContainer {
    /* background-color: #18353e; */
    background-color: #535353;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
}


.AudioReaderBox {
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.audioBookTopBar {
    flex: 3;
    /* height: 5%; */
    /* background-color: #214753; */
    background-color: #434343;
    display: flex;
    align-items: center;
}

.audioBookTopBar span {
    /* color: var(--color-blue-add1); */
    color: #DB7940;
}

.audioBookTopBar>* {
    cursor: pointer;
}

.audioBookBackButton {
    all: unset;
    padding: 0 20px;
    cursor: pointer;
    width: 16px;
}

.audioBookPlayerContainer {
    height: 95%;
    display: flex;
    flex-direction: row;
    padding: 20px 20px;
    box-sizing: border-box;
    flex: 97;
    justify-content: space-between;
    gap: 1rem;
    /* min-height: 600px; */
}

.topicController {
    background-color: white;
    flex: 1;
    /* max-width: 450px; */
}

.topicControllerPhone {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.4);
}

.audioBookController {
    background-color: white;
    flex: 4;
    /* max-width: 450px; */
    position: relative;
}

.audioBookControllerTopSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.audioBookName {
    font-weight: bold;
}

.audioBookAuthor {
    font-size: 12px;
    margin-top: 10px;
}

.audioBookChapter {
    margin-top: 20px;
    font-weight: bold;
}

.audioBookCoverImageContainer {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.audioBookCoverImage {
    display: flex;
    width: 300px;
    min-height: 360px;
    max-width: 100%;
    height: 50vh;
    flex-direction: row;
    padding-left: 3rem;
}

.audioBookCoverImagePhone {
    display: flex;
    /* width: 300px; */
    min-height: 360px;
    /* max-width: 100%; */
    height: 50vh;
    flex-direction: row;
}

.audioBookCoverImageText {
    font-size: 12px;
    font-weight: bold;
    margin-top: 12px;
}

.audioBookCoverImageButtonsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.audioBookCoverImageButton {
    height: 10px;
    border-radius: 5px;
    margin-left: 4px;
    border: none;
}

.audioBookCoverImageNonActiveButton {
    width: 20px;
    background-color: #e5e5e5;
}

.audioBookCoverImageActiveButton {
    width: 60px;
    /* background-color: var(--color-blue-add25); */
    background-color: #DB7940;

}

.audioBookChapterList {
    /* position: absolute; */
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.audioBookChapterListPhone {
    /* position: absolute; */
    background-color: white;
    width: fit-content;
    /* max-width: 50%; */
    height: 100%;
    padding: 20px;
    /* display: flex; */
    /* flex-direction: column; */
    /* z-index: 100; */
    overflow: auto;
}

/* .audioBookChapterItemsContainer {
    overflow: auto;
} */

.audioBookProgressBarContainer {
    display: flex;
    justify-content: center;
    height: 20px;
    align-items: center;
    margin-top: 50px;
    flex-direction: row;
    gap: 1rem;
}

input[type="range"].audioBookRangeBar {
    -webkit-appearance: none;
    /* margin-top: 40px; */
    width: 80%;
    /* overflow: hidden; */

}

progress[value] {
    /* margin-top: 40px; */
    width: 100%;
    height: 10px;
    appearance: none;
    border-radius: 5px;
}

progress::-webkit-progress-bar {
    background-color: #e5e5e5;
    border-radius: 5px;
}

progress::-webkit-progress-value {
    background-color: #DB7940;
    border-radius: 5px;
}

progress::-moz-progress-bar {
    background-color: #DB7940;
    border-radius: 5px;
}

.audioBookControlButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    /* margin-top: 20px; */
}

.audioBookChapterGroupTitle {
    /* height: 40px;
    line-height: 40px;  */
    height: 50px;
    line-height: 50px;
    /* font-weight: bold; */
    /* color: #000000; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    align-items: center;
}

.audioBookChapterGroupTitle > img{
    padding-left: 12px;
    height: 25px;
}

.audioBookChapterItem {
    height: 40px;
    font-size: 14px;
    border-bottom: 1px solid #f5f5f5;
    line-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.audioBookChapterTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.audioBookTOCTopSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.audioBookControlButton {
    all: unset;
    cursor: pointer;
}

.audioBookControlLargeIcon {
    width: 60px;
}

.audioBookControlSmallIcon {
    width: 30px;
}

.audioBookSubTitleContainer {
    display: flex;
    flex-direction: column;
    /* background-color: black; */
    color: darkgray;
    width: 100%;
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
}

.audioBookSubTitleContainer h1 {
    font-size: 3vh;
}

.coverimg {
    object-fit: contain;
}

.dialogButtonContainer,
.dialogButtonContainer2 {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

@media screen and (max-Height:700px) {
    .audioBookProgressBarContainer {
        margin-top: auto;
    }
}

.swiper-slide {
    width: 80%;
}

.progressTrigger {
    background-color: rgb(219, 121, 64);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: none;
    position: absolute;
    border: solid grey;
}

.progressHoverTime {
    background-color: rgb(83, 83, 83);
    height: 20px;
    width: 40px;
    border-radius: 10px;
    position: absolute;
    color: white;
    font-size: 13px;
    text-align: center;
    padding-top: 2px;
}