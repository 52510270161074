.treeMenu {
  border-top: 0.5px solid lightgray;
  overflow: hidden;
  font-size: 0.8em;
  cursor: pointer;
}
.treeHeadActive {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background-color: #fcf1eb;
}

.treeHeadActive img {
  width: 20px;
  height: 20px;
}
.treeBodyActive {
  transition: max-height 1s linear;
  max-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 4em 0 4em;
  background-color: #fcf1eb;
}

.treeBodyActive :hover {
  background-color: #f8dfd2;
  padding: 0;
}

.treeBodyActive > a {
  color: var(--color-orange);
}
.treeHead {
  display: flex;
  justify-content: space-between;
  margin: 1em;
}
.treeBody {
  /* transition: max-height 0.5s; */
  max-height: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 0 0 3em;
}
.treeBody > a {
  /*TODO menu*/
  color: transparent;
}

/* .treeMenu button{
    display:flex;
    padding:1em 1em 1em 1em;
} */

.treeBody > button,
.treeBodyActive > button {
  /* display:flex; */
  margin: 0em 0 1em 0;
}

.iconConatiner {
  display: flex;
}

.iconConatiner button {
  font-size: 1em;
  color: var(--color-orange);
  margin-right: 1rem;
}

.addSubCategoryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  border-radius: 50px;
  background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
  color: white;
  min-width: 80px;
  min-height: 25px;
}

.sortIconContainer {
  display: flex;
}


.treeHeadGrid{
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  width: 100%;
  justify-items: start;
  align-items: center;
}