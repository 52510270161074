.videoBookContainer>* {
    -webkit-touch-callout: none !important;
    /* iOS Safari */
    -webkit-user-select: none !important;
    /* Safari */
    -khtml-user-select: none !important;
    /* Konqueror HTML */
    -moz-user-select: none !important;
    /* Firefox */
    -ms-user-select: none !important;
    /* Internet Explorer/Edge */
    user-select: none !important;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.videoBookContainer {
    /* background-color: #18353e; */
    background-color: #535353;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
}


.VideoReaderBox {
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.VideoReaderBoxVideo {
    display: flex;
    flex-direction: column;
    height: 100%;

}

.videoBookTopBar {
    flex: 3;
    /* height: 5%; */
    /* background-color: #214753; */
    background-color: #434343;
    display: flex;
    align-items: center;
}

.videoBookTopBar span {
    /* color: var(--color-blue-add1); */
    color: #DB7940;
}

.videoBookTopBar>* {
    cursor: pointer;
}

.videoBookBackButton {
    all: unset;
    padding: 0 20px;
    cursor: pointer;
    width: 16px;
}

.videoBookPlayerContainer {
    height: 95%;
    display: flex;
    flex-direction: row;
    padding: 20px 20px;
    box-sizing: border-box;
    flex: 97;
    justify-content: space-between;
    gap: 1rem;
    /* min-height: 600px; */
}

.topicController {
    background-color: white;
    flex: 1;
    /* max-width: 450px; */
}

.topicControllerPhone {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.4);
}

.videoBookController {
    background-color: white;
    flex: 4;
    /* max-width: 450px; */
    position: relative;
}

.videoBookControllerTopSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.videoBookName {
    font-weight: bold;
}

.videoBookAuthor {
    font-size: 12px;
    margin-top: 10px;
}

.videoBookChapter {
    margin-top: 20px;
    font-weight: bold;
}

.videoBookCoverImageContainer {
    width: 100%;
    height: 100%;
    /* margin-top: 30px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.videoBookCoverImage {
    position: relative;

    display: flex;
    width: 80%;
    /* min-height: 360px;
    max-width: 100%; */
    height: 80%;
    flex-direction: row;
    /* padding-left: 3rem; */
}

.videoBookCoverImagePhone {
    display: flex;
    /* width: 300px; */
    min-height: 360px;
    /* max-width: 100%; */
    height: 50vh;
    flex-direction: row;
}

.videoBookCoverImageText {
    font-size: 12px;
    font-weight: bold;
    margin-top: 12px;
}

.videoBookCoverImageButtonsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.videoBookCoverImageButton {
    height: 10px;
    border-radius: 5px;
    margin-left: 4px;
    border: none;
}

.videoBookCoverImageNonActiveButton {
    width: 20px;
    background-color: #e5e5e5;
}

.videoBookCoverImageActiveButton {
    width: 60px;
    /* background-color: var(--color-blue-add25); */
    background-color: #DB7940;

}

.videoBookChapterList {
    /* position: absolute; */
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.videoBookChapterListPhone {
    /* position: absolute; */
    background-color: white;
    width: fit-content;
    /* max-width: 50%; */
    height: 100%;
    padding: 20px;
    /* display: flex; */
    /* flex-direction: column; */
    /* z-index: 100; */
    overflow: auto;
}

/* .videoBookChapterItemsContainer {
    overflow: auto;
} */

.videoBookProgressBarContainer {
    display: flex;
    justify-content: center;
    height: 20px;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 10px;
    flex-direction: row;
    gap: 1rem;
}

input[type="range"].videoBookRangeBar {
    -webkit-appearance: none;
    /* margin-top: 40px; */
    width: 80%;
    /* overflow: hidden; */

}

progress[value] {
    /* margin-top: 40px; */
    width: 100%;
    height: 10px;
    appearance: none;
    border-radius: 5px;
}

progress::-webkit-progress-bar {
    background-color: #e5e5e5;
    border-radius: 5px;
}

progress::-webkit-progress-value {
    background-color: #DB7940;
    border-radius: 5px;
}

progress::-moz-progress-bar {
    background-color: #DB7940;
    border-radius: 5px;
}

.videoBookControlButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    /* margin-top: 20px; */
}

.videoBookChapterGroupTitle {
    /* height: 40px;
    line-height: 40px;  */
    height: 50px;
    line-height: 50px;
    /* font-weight: bold; */
    /* color: #000000; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    align-items: center;
}

.videoBookChapterGroupTitle > img{
    padding-left: 12px;
    height: 25px;
}

.videoBookChapterItem {
    height: 40px;
    font-size: 14px;
    border-bottom: 1px solid #f5f5f5;
    line-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.videoBookChapterTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.videoBookTOCTopSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.videoBookControlButton {
    all: unset;
    cursor: pointer;
}

.videoBookControlLargeIcon {
    width: 60px;
}

.videoBookControlSmallIcon {
    width: 30px;
}

.videoBookSubTitleContainer {
    display: flex;
    flex-direction: column;
    /* background-color: black; */
    color: darkgray;
    width: 100%;
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
}

h1 {
    height: 6vh;

    color: darkgray;
    font-size: 3vh;
    padding: 1rem;
    text-align: center;
}

.coverimg {
    object-fit: contain;
}

.dialogButtonContainer,
.dialogButtonContainer2 {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

@media screen and (max-Height:700px) {
    .videoBookProgressBarContainer {
        margin-top: auto;
    }
}

.swiper-slide {
    width: 80%;
}

.progressTrigger {
    background-color: rgb(219, 121, 64);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: none;
    position: absolute;
    border: solid grey;
}

.progressHoverTime {
    background-color: rgb(83, 83, 83);
    height: 20px;
    width: 40px;
    border-radius: 10px;
    position: absolute;
    color: white;
    font-size: 13px;
    text-align: center;
    padding-top: 2px;
}

@media screen and (max-Height:1010px) {
    .videoBookControllerTopSection {
        position: absolute;
        width: calc(100% - 20px - 20px);
    }
    .videoBookChapter {
        position: absolute;
        max-width: calc(100% - 20px - 20px);

        display: none;
    }
    .videoBookCoverImageContainer {
        margin-top: 0;
    }
    .videoBookCoverImage {
        width: 96%;
        height: calc(100% - 60px);
    }
    .videoBookSrt {
        position: absolute;
        bottom: 50px;
        width: calc(100% - 20px - 20px);
    }
    .videoBookProgressBarContainer {
        position: absolute;
        bottom: 6px;
        left: 230px;
        right: 10px;
    }
    .videoBookControlButtonsContainer {
        position: absolute;
        bottom: 10px;
        left: 50px;
        width: 108px;
        gap: 8px;
    }
    .videoBookControlLargeIcon {
        width: 32px;
    }
}