.Header {
    display: flex;
    justify-content: space-between;
}
  
  .backHomeButtonContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .backHomeButtonContainer  img {
    /* height: 15px; */
    margin: 0 1em 0 0;
  }
  .backHomeButtonContainer  button {
    color: rgba(181 60 63);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .pageNav {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }

  .pageNav * {
    font-size: 0.7rem;
  }
  
  .ButtonContainer > button {
    color: rgba(181 60 63);
  }

  .ButtonContainer{

  }

  .RecordContainer{
    padding-top: 30px;
  }


  .toggleGroup button{
    width: 50%;
    font-size: 18px;
    font-weight: bold;
  }


  .container h4{
    font-size: 32px;
    margin: 15px 0;
  }



.border_line{
  border-right: 2px solid;
  height: 100%;
  margin: 0 8px;
}


.row{
  white-space: nowrap;
  padding: 0 10px 0 0;
  display: flex;
}

.RowBtn{
  margin-left: -45px;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  align-items: center;
  padding: 10px 16px;
  text-align: left;

  display: grid;
  grid-template-columns:  auto  auto;
}



.banner_contain{
  width: 100%;

}

.banner_row{
  max-height: 70px;
  min-height: 65px;
  width: 100%;
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  margin: 10px 0;
}

.Row{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.banner_row_text{
  display: flex;
  align-items: center;
  line-height: 51px;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-overflow: ellipsis;
  text-align: left;
  padding: 0px 16px;
}

.banner_row_btn{
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.banner_row_btn button{
  width: fit-content;
  margin: 0;
  min-width: 60px;
}

.banner_row_btn button:nth-child(1){
  padding: 10px;
}


.tableHeaderItem{
  min-width: 75px;
}


@media screen and (max-width: 560px) {
  
  .header {
     grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  }

  .header>div{
    margin: 10px 0;
    padding: 0;
  }

  .rowSpan {
    justify-content: space-evenly;
  }

  .row>div:nth-child(1){
    width: 100%;
  }
  
}