.Header {
    display: flex;
    justify-content: space-between;
}
  
  .backHomeButtonContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .backHomeButtonContainer  img {
    /* height: 15px; */
    margin: 0 1em 0 0;
  }
  .backHomeButtonContainer  button {
    color: rgba(181 60 63);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .pageNav {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }

  .pageNav * {
    font-size: 0.7rem;
  }
  
  .ButtonContainer > button {
    color: rgba(181 60 63);
  }

  .ButtonContainer{

  }

  .RecordContainer{
    padding-top: 30px;
  }


  .toggleGroup button{
    width: 50%;
    font-size: 18px;
    font-weight: bold;
  }


  .container h4{
    font-size: 32px;
    margin: 15px 0;
  }



.border_line{
  border-right: 2px solid;
  height: 100%;
  margin: 0 8px;
}



.tableHeader {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  align-items: center;
  padding: 10px 16px;
  text-align: left;

  display: grid;
  grid-template-columns:  auto  auto;
}



.tableHeaderItemCol1 h6{
    /* font-size: 25px; */
}

.from{
    display: flex;
    flex-direction: column;
}

.from label{
    font-size: 18px;
    margin: 8px 0;
}

.form_control_lg{
    display: block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.banner_size_tips{
    display: inline-block;
    border: 1px solid darkgray;
    border-radius: 8px;
    padding: 8px 50px 8px 8px;
    width: fit-content;
    margin: 15px 0;
}

.mobile_banner_image_tips
{
    color: red;
    font-size: smaller;
}

.imgPreview{
    max-width:180px;
    margin: 15px 0;
}
.inputImage{
  padding:10px;
  background:var(--color-orange-add70);
}

.from button{
    margin: 10px 0;
    padding: 1%;
    width: fit-content;
}



.delete_btn{
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-shadow: 0 2px 0 rgba(0,0,0,.015);
  cursor: pointer;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0,0,0,.85);
  background: #fff;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  padding: 2% !important;
}



.anticon_delete{
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}





.banner_contain{
  width: 100%;

}

.banner_row{
  max-height: 70px;
  min-height: 65px;
  width: 100%;
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  margin: 10px 0;
}

.Row{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.banner_row_text{
  display: flex;
  align-items: center;
  line-height: 51px;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-overflow: ellipsis;
  text-align: left;
  padding: 0px 16px;
}

.banner_row_btn{
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.banner_row_btn button{
  width: fit-content;
  margin: 0;
  min-width: 60px;
}

.banner_row_btn button:nth-child(1){
  padding: 10px;
}


.tableHeaderItem{
  min-width: 75px;
}



@media screen and (max-width: 560px) {
  
  .header {
     grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  }

  .header>div{
    margin: 10px 0;
    padding: 0;
  }

  .rowSpan {
    justify-content: space-evenly;
  }

  .row>div:nth-child(1){
    width: 100%;
  }
  
}