


.bookmarkRecord_row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid;
  padding: 0 0 6px 0;
}


.bookmarkRecord_row_btn{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.delete_btn{
  width: 18px !important;
  height: 18px !important;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  /* position: absolute;
  top: 3px !important;
  right: 6px !important; */
  z-index: 10;
  animation: popup 0.1s ease-in-out 0s 1;
  background: var(--color-orange-add70);
  visibility: hidden;
}

.bookmarkRecord_row:hover .delete_btn{
  visibility: visible;
}
