.treeMenu{
    border-top: .5px solid lightgray;
    overflow: hidden;
padding: 1em;
}

.treeHead{
    display:flex;
    justify-content:space-between;
    /* margin: 1em; */
    align-items:center;
}

.date{
    font-size: 0.6em;
    margin-bottom: auto;
}

.treeMenu>p{
    margin: 1em 0  0  0;
    font-size: 0.7em;
}

.bookCover {
    display: flex;
    height: 160px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

.bookIndex{
    padding: 0.3em 0.5em 0.3em 0.5em;
    margin: 0%;
    background-color: rgb(252,236,208);
    width: fit-content;
    height: fit-content;
    margin-right: 1em;
}
.bookInfo{
    position: relative;
    left: .3em
}

.bookName{
    text-align: start;
    margin-bottom: auto;
    font-size: 1rem;
}