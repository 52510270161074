.Container {
    min-width: 200px;
    max-width: 250px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    border-radius: 8px;
    /* max-height: 300px; */
    margin: 10px 7px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dialogButtonContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

.CardView_IMG {
    display: flex;
    justify-content: center;
    margin: 0 8px 0;
    padding-top: 8px;
    position: relative;
   
}

.Reservationbtn button{
  max-width: initial;
  margin-top: 10px;
}


.CardView_IMG img{
box-shadow: 1px 1px 1px 1px rgb(152 151 151);
cursor: pointer;
}

.CardView_IMG button{
    position: absolute;
    padding: 10px 30px;
    top: 65px;
}

.Content>div {

    margin: 4px;
   
}

.Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 8px; */
    flex-wrap: nowrap;
    width: 200px;
    align-items: stretch;
}

.Content h5{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}



.ContentDate{
    font-size: 10px;
    margin: 15px 0 0 4px  !important;
}

  .Container ::-webkit-scrollbar-track, .TableCardViewContent ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  .Container::-webkit-scrollbar, .TableCardViewContent::-webkit-scrollbar {
    width: 2px;
    background-color: white;
  }

  .Container::-webkit-scrollbar-thumb , .TableCardViewContent::-webkit-scrollbar-thumb{
    background-color: black;
  }



  @media screen and (max-width: 768px){
    .Container{
        margin: 10px 0px 10px;
    }
}