.Header {
    display: flex;
    justify-content: space-between;
}
  
  .backHomeButtonContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .backHomeButtonContainer  img {
    /* height: 15px; */
    margin: 0 1em 0 0;
  }
  .backHomeButtonContainer  button {
    color: rgba(181 60 63);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .ContentLayout {
    display: flex;
    margin: 2em 0 0 0;
    flex-direction: column;
}


.ContentItem_container{
    max-width: 420px;
}

.ContentItem_container div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 7px 0;
}

.btn{
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
    max-width: 420px;
}

.btn button{
    width: 30%;
    margin: 0 5px;
}

.title > h1 {
  font-size: 32px;
}