.BookDetailHeader {
  display: flex;
  justify-content: space-between;
}

.backHomeButtonContainer,
.category {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.backHomeButtonContainer  img {
  /* height: 15px; */
  margin: 0 1em 0 0;
}
.backHomeButtonContainer  button {
  color: rgba(181 60 63);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}



.category * {
  font-size: 0.7rem;
}

.categoryButtonContainer > button {
  color: rgba(181 60 63);
}

.pageContentLayout {
  display: flex;
  margin: 2em 0 0 0;
}
.bookDetailsContainer {
  min-height: 300px;
  box-shadow: 1px -1px 5px 1px rgb(235 235 235);
  border-radius: 5px;
  flex: 7;
  margin: 0 2em 0 0;
  padding: 2em;
}

.bookInventoryContainer {
  height: 300px;
  box-shadow: 1px -1px 5px 1px rgb(235 235 235);
  border-radius: 5px;
  flex: 2;
}
.bookDetailsContainer {
  display: flex;
}
.buttonGroup {
  display: flex;
  margin: 1em 0 0 0;
  align-items: stretch;
  flex-direction: column;
}

.buttonGroup div{
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonGroup button {
  color: white;
  background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
  width: 100%;
  border-radius: 50px;
  min-height: 30px;
  /* font-size: 0.7em; */
  max-width: 85px;
  font-size: 0.7em;
  min-width: 50px;
  padding: 6px;

}

.buttonGroup img {
  width: 80%;
  width: 100%;
  min-width: 26px;
}

.dialogButtonContainer, .dialogButtonContainer2{
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.bookLayout {
  width: 100%;
  width:auto;
  height: 100%;
  margin-right: 15px;
}
.bookImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 130px; */
  height: 180px;
  max-height: 180px;
}

.bookImgContainer > img {
  /* width: 100%; */
  /* height: 100%; */
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-shadow: 1px 0px 8px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 0px 8px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 0px 8px -2px rgba(0, 0, 0, 0.75);
}

.bookDetailLayout {
  margin: 0 0 0 2em;
  min-width: 250px;
}

.subDetails {
  display: flex;
  flex-direction: column;
  max-width: 250px;
}

.subDetails > div > p:first-child {
  /* margin: 0 2em 0 0; */
  width: 30%;
}

.subDetailscategory{
  width: 70%;
  display: flex;
  flex-flow: wrap;
}

.subDetails > div{
  display: flex;
}

.subDetails p {
  font-size: 0.7em;
  margin: 0 0 0.7em 0;
  min-height: 1em;
}

.bookDetailLayout > .clickRate {
  color: grey;
  font-size: 0.7em;
}

div > .author {
  margin: 2em 0 2em 0;
  font-size: 0.8em;
  color: rgba(181 60 63);
  cursor: pointer;
}

.subDetails > div:nth-child(2) > p:nth-child(4) {
  color: rgba(181 60 63);
  cursor: pointer;
}

.bookInventoryContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex{
  display: flex;

}

.bookmark{
  cursor: pointer;
  padding-right: 10px;
}

.bookInventoryContainer > div button {
  color: rgba(181 60 63);
  background: var(--color-orange-add70);
  width: 70px;
  border-radius: 50px;
  height: 25px;
  font-size: 0.8em;
  margin: 2em 0 0 0;
}

.toggleGroupContainer {
  box-shadow: 1px -1px 5px 1px rgb(235 235 235);
  padding: 2em;
  margin: 2em 0 0 0;
  position: relative;
}

.toggleGroup > button {
  width: 50%;
  font-size: 15px;
}

.commentInnerContainer{
  margin: 2em 0 2em 0
}

.introParagraph p {
  font-size: 0.9em;
  overflow-wrap: break-word;
  font-size: 1.1em;
  overflow-wrap: break-word;
  line-height: 2em;
  letter-spacing: 0.06em;
}


.bookComments p {
  font-size: 0.7em;
  overflow-wrap: break-word;
}


.introParagraphNonActive, .bookCommentsNonActive {
  display: none;
}



.commenterInfo{
  display: flex;

}

.commentBoxInnerContainer{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 0 0 25px;
}

.commentContainer{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 20px 0 25px;
  border-top: 1px solid darkgray;
}

.commentBoxInnerContainer {
  /* font-size:0.7em; 
  flex : 0 0 5rem*/
}

/* .commentBoxInnerContainer > div:nth-child(3){
  flex : 1
} */

.rating{
  width: 100px;
}
.commenter{
  margin:  0 0 0 2em;
  font-size:0.7em;
  width: 100px;
  word-wrap: break-word;
}

.comment{
  width: 100%;
  display: flex;
  flex-direction: row;
}

.commentText{
  width: 100%;
    word-break: break-word;
}
.commentTextarea{
  width: 100%;
}

.commentDate{
  margin-left: 5px;
}

.commentButton{
  margin: 0 0 0 3px;
  display: flex;
  width: 70px;
  align-items: center;
}
.commentButton{
  margin: 0 0 0 2em;
}

.commentButton > button{
  color: rgba(181 60 63);
  background: pink;
  width: 50px;
  border-radius: 50px;
  height: 25px;
  font-size: 0.8em;
  width: 100%;
  color: rgba(181 60 63);
  background: var(--color-orange-add70);
  /* width: 50px; */
  border-radius: 50px;
  height: fit-content;
  font-size: 0.7em;
  /* width: fit-content; */
  white-space: nowrap;
  padding: 7px;
}
.relatedBookContainer{
  box-shadow: 1px -1px 5px 1px rgb(235 235 235);
  padding: 2em;
  margin: 2em 0 0 0;
  position: relative;
}
.relatedBookInnerContainer{
  display: flex;
  /* justify-content: space-between; */
      /* height: 100%; */
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      margin: 1em 1em 0 0;
}

.relatedBookContainer > h4{
  margin: 0 0  1em 0;
}

.bookInventoryDetail{
  margin: 0 10px;
}



.bookInventoryDetail div{
  margin: 0 0  1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookInventoryDetail h5{
  margin: 0 0 10px;
}

.star{
  margin: 1em 0 0 0 ;
}

.pageStatus{
  color: #b53c3f;
  cursor: pointer;
}

.pageNone{
  display: none;
}

.dialogButtonContainer{
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}


.dialogButtonContainer3{
  display: flex;
  justify-content: center;
  margin-top: 15px;
  align-items: center;
}

.dialogButtonContainer button:last-child , .dialogButtonContainer3 button:last-child{
  color: white;
  background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
  width: 100%;
  border-radius: 50px;
  height: 35px;
  /* font-size: 0.7em; */
  max-width: 90px;
  margin: 0 3px;
}

.dialogButtonContainer button:first-child , .dialogButtonContainer3 button:first-child{
  color: rgba(181 60 63);
  background: var(--color-orange-add70);
  width: 100%;
  border-radius: 50px;
  height: 35px;
  /* font-size: 0.7em; */
  max-width: 90px;
}

.pad div{
  padding: 4px 0;
}
.pad > div {
  display: inline;
}

.pad button{
  font-size: 0.8em;
}
.pad .pad_small_text {
  font-size: 70%;
}

@media screen and (max-width: 768px) {
  .pageContentLayout {
    flex-direction: column;
  }

  .bookDetailLayout {
    margin: 0;
  }
  
  .bookDetailsContainer {
    margin: 0;
  }

  .bookInventoryContainer {
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
  }

  .InventoryRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
  }
  
  .InventoryRow div{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 8px;
  }

  .bookInventoryDetail{
    display: flex;
    padding: 20px;
    align-items: center;
  }


  .bookInventoryDetail div{
    margin: 0 7px;
  }


  .bookInventoryDetail h5{
    margin: 0;
  }

  .bookInventoryContainer > div button {
    margin: 0;
  }

 
}





@media screen and (max-width: 480px) {



  .category {
  width: 50%;
  }

  .bookDetailsContainer {
    flex-direction: column;
    align-items: center;
  }

  .bookInventoryDetail{
    flex-direction: column;
  }

  .InventoryRow{
    padding: 15px;
  }

  .InventoryRow div{
    padding: 0 15px;
  }

  .commenter{
    margin:  0;

  }
  .commentBoxInnerContainer{
    flex-direction: column;
    align-items: flex-start;
  }

  .commentContainer{
    flex-direction: column;
    align-items: flex-start;
  }
  
  .commenterInfo{
    margin: 0 0 15px;
  
  }

  .buttonGroup {
    justify-content: center;
  }

  .bookLayout {
    margin: 0;
  }

  .star{
    display: flex;
    justify-content: center;
  }

  .bookDetailLayout {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
  }


  .flex{
    justify-content: center;
  }

}

@media screen and (max-width: 428px) {
  .pad > div {
    display: block;
  }
}