.exContainer {
  box-shadow: 1px -1px 5px 1px rgb(235 235 235);
  padding: 2em;
  margin: 2em 0 0 0;
  width: 100%;
}

.exContainer * {
  max-width: 100% !important;
  max-height: 100% !important;
  word-break: break-word;
}

.exContainer > div {
  margin: 1em 0 0 0;
  font-size: 0.8rem
}
