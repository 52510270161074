@import '/src/assets/color.css';


.Navbar{
  z-index: 2;
  position: sticky;
  top: 0;
}

.upperNavbar {
  /*TODO upperNavbar*/
  height: 70px;
  /* background: linear-gradient(90deg, rgba(232 112 60) 0%, var(--color-red-main) 100%); */
  background : white;
  z-index: 2;
  position: sticky;
  top: 0;
}

.innerUpperNavbar {
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  height: 100%;
  padding: 0 0 0 1em;
}

.innerUpperNavbar ul, .lowerInnerNavbar ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0 1em 0 0;
  color: white;
  font-size: 0.7rem;
}

.innerUpperNavbar ul li button, .lowerInnerNavbar ul li button {
  font-size: 0.7rem;
  /* color: white;  */
  /* padding: 0 0 0 1em; */
  /* height: 100% */
  white-space:nowrap;
  color: var(--color-red-main);
  font-size: 1.3em;
}

.innerUpperNavbar button>img, .lowerInnerNavbar button>img{
    height:24px
}

.lowNavBarButtonContainer {
    color: var(--color-red-main);
    padding: 0;
}

.lowNavBarButtonContainer li, 
.lowNavBarButtonContainer li button{
    color:var(--color-red-main);
}



.logoContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  color: white;
}

.logoContainerHkpl{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  color: white;
  direction: rtl;
}


.hkplLogo{
  height: 60px;
  object-fit: contain;
  width: auto;
  margin: 8px;
  max-width: 260px;
}

.mainLogo{
  margin: 8px;
  max-width: 160px;
}

.secondLogo{
  margin: 8px;
  max-width: 160px;
}

.logoContainer img {
  width: 18%;
  width: 200px;
  width: 100%;
  max-width: 200px ;
}

.lowerNavbar {
  height: 45px;
  background: rgba(253 239 232);
  z-index: 2;
  position: sticky;
}
.lowerInnerNavbar {
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  height: 100%;
  padding: 0 0 0 1em;
}

.logo{
  width: 25px;
}


.searchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 1em 0 0; */
}
.searchContainer > div {
  height: 60%;
}

.searchContainer a button {
  /*TODO Navbar*/
  font-size: 1rem;
  color:var(--color-red-main);
  padding: 0 1em 0 1em;
}

.SideBtn {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}


.header_box{
  background: #fff;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, .5);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  outline: none;
  position: absolute;
  border-radius: 2px;
  position: absolute;
  z-index: 3;
  box-sizing: border-box;
  border-top: 3px var(--color-orange-reduce15) solid;
  width: 130px;
  bottom: -90px;
}


.header_box:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color:  var(--color-orange-reduce15) transparent;
  border-width: 0 13px 17px;
  top: -17px;
  left: 17%;
  margin-left: -15px;

}


.header_contain{
  font-size: 16px;
  align-items: center;
  justify-content: center;
  /* overflow-y: auto; */

}


.nav_item{
  overflow: hidden;
  width: 100%;
  padding: 5px 10px;
  text-align: left;
  box-sizing: border-box;
  background-color: #ffffff;
  border-bottom: 1px #555555 double;
  color: #000 !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav_item:hover{
  color: var(--color-orange) !important;
  /* background-color: var(); */
}

.nav_item:nth-child(3){
  border-bottom: 0px;
}

.nav_item img{
  margin-left: 5px;
  width: 22px;
  height: 22px;
}


.borderLine{
  border-right: 2px solid;
  height: 80%;
  margin: 0 8px;
}

.flex{
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-red-main);
}
.flex div{
 margin: 0 2px;
 
}
.searchContainer button{
  color: var(--color-red-main);
 }




/*TODO Navbar*/
@media screen and (max-width: 1024px) {
  .upperNavbar{
    /* height: 60px; */
  }
  .innerUpperNavbar ul li button, .lowerInnerNavbar ul li button {
    font-size: 0.7rem;
  }
  .searchContainer a button {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 768px) {
  .searchContainer img{
      width: 25px;
  }

  .lowerInnerNavbar {

    padding: 0 1em 0 1em;
  }
}

@media screen and (max-width: 640px) {
  .hkplLogo{
    height: 60px;
    margin: 2px;
    max-width: 210px;
  }
  
  .mainLogo{
    max-width: 120px;
  }

  .secondLogo{
    max-width: 120px;
  }
}



@media screen and (max-width: 430px) {
  .hkplLogo{
    height: 60px;
    max-width: 150px;
    max-width: 120px;
  }
  
  .mainLogo{
    max-width: 90px;
    max-width: 75px;
  }
  .secondLogo{
    max-width: 90px;
  }
}


@media screen and (max-width: 391px) {
  /* .hkplLogo{
    height: 60px;
    max-width: 120px;
  }
  
  .mainLogo{
    max-width: 80px;
  }
  .secondLogo{
    max-width: 80px;
  } */

  .logoContainer img:nth-child(2){
    width: 55%;
  }
}

@media screen and (max-width: 320px) {
  /* .hkplLogo{

    max-width: 100px;
  }
  
  .mainLogo{
    max-width: 80px;
  }
  .secondLogo{
    max-width: 80px;
  } */

}


