

.treeMenu{
    border-top: .5px solid lightgray;
    overflow: hidden;
    font-size:0.9rem;
    cursor: pointer;
}
.treeHeadActive{
    display:flex;
    justify-content:space-between;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 1em;
    padding-left: 2em;
    background-color: #fcf1eb;
}

.treeHeadActive img{

width: 20px;
height: 20px;

}
.treeBodyActive{
    transition: max-height 1s linear;
  
    max-height:10em;
    display: flex;
    flex-direction:column;
    align-items:start;
    padding: 0 0 1em 3em;
    background-color: #fcf1eb;
}
.treeBodyActive > a{
    color: var(--color-orange);
}
.treeHead{
    display:flex;
    justify-content:space-between;
    margin-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 1em;
}
.treeBody{
    /* transition: max-height 0.5s; */
    max-height:0;
    display: flex;
    flex-direction:column;
    align-items:start;
    margin: 0 0 0 3em;
}
.treeBody > a{
    /*TODO menu*/
    color: transparent;
}

/* .treeMenu button{
    display:flex;
    padding:1em 1em 1em 1em;
} */

.treeBody>button, .treeBodyActive>button {
    /* display:flex; */
    margin:0em 0 1em 0
}

