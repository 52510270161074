@import '/src/assets/color.css';

.Header {
    display: flex;
    justify-content: space-between;
}
  

  .category {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  
  /* flex-direction: column-reverse; */
  
  .category * {
    font-size: 0.7rem;
  }
  
  .categoryButtonContainer > button {
    color: rgba(181 60 63);
  }
  

  .open_Nav{
    color: var(--color--orange);
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    z-index: 5;
    margin: 15px 0px 0px 25px;
    display: none;
  }
  
  .searchArea{
    display: grid;
    grid-template-columns: auto auto auto 1fr auto;
    grid-template-columns:repeat(auto-fit, minmax(270px, 1fr));
   
  }
  
  .selectOptionContainer{
    display: flex;
    width: 80%;
    flex-flow: wrap;
  }

  .searchArea>div{    
    display: flex;
    width: auto;
    margin: 10px
  }



.tableItemListContainer {

    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
    margin: 10px 0;
}

.tableListItemGridView{
padding: 0 0 10px 0;
}
.tableListItemGridView button{
    min-width: 70px;
}
/* .TableListviewRow_grid{
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(3, 1fr) ;
} */


.buttonRow{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-wrap: wrap;
    margin: 25px 0;
}
.buttonRow button{
  padding: 12px;
  margin: 0px 6px 6px;
  width: fit-content;
  min-width: 100px;
}

.titleRow {
    font-weight: bold;
    padding: 15px;
    background-color: bisque;
    border-radius: 10px 10px 0 0;
}

.content{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr) ;
    grid-template-columns:repeat(auto-fit, minmax(300px, 1fr));
    padding: 10px 40px 25px;
}


.contentItem{
    margin: 5px;
}

.searchButtonContainer button{
  margin:  0 10px;
}


.selectMargin{
  margin-top: 15px;
}

.selectMarginLong{
  margin-top: 15px;
  flex: 2 !important;
}

.selectOptionsRow{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.selectOptionsRow label{
font-size: 13px;
}

.selectOptionsRow2{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.selectOptionsRow div{
  width: 98%;
  margin: 0 auto;
  flex: 1;
}

.selectOptionsRow2 div{
  width: 100%;

}

.inputtext input{
  min-height: 48px;
  width: 98%;
  margin: 0 auto;
  display: flex;
}

.selectOptionsRow .InputKeyword {
  min-height: 48px;
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex: 3;
}


.selectOptionsRow .inputOption {
  min-height: 48px;
  width: 98%;
  margin: 0 auto;
  display: flex;
}


.page{
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.page_row{
  display: flex;
  justify-content: center;
  margin: 10px;
  align-items: center;
}


.page_item{
  cursor: pointer;
  margin: 0 10px;
}


@media screen and (max-width: 830px) {
  .selectMargin, .selectMarginLong{
    margin-top: 30px;
  }

  .inputOption{
    margin-top: 30px;
  }

  .selectOptionsRow2{
    margin-top: 0;
  }

  .searchButtonContainer button{
    display: block;
    margin:  0 auto;
    margin-top: 7px;
  }
}


@media screen and (max-width: 360px) {
  .content {
    padding: 10px 2px 25px;
  }

  .searchButtonContainer {

    display: flex;
    justify-content: flex-end
  }

}

@media screen and (max-width: 480px) {
  .content {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    padding: 10px 8px 25px;
  }
}