.tableItemCard {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 8px;
  height: auto;
  margin: 5px;
  max-width: 320px;
}

.tableItemCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.tableItemCardContainer {
  /* margin: 0 auto 8px; */
  padding: 16px;
  height: 100%;
}
.tableListItemGridView {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  justify-content: space-between;
  word-break: break-all;
  grid-template-areas:
    "main side ."
    "isbn side ."
    "main2 side ."
    "footer footer .";

  grid-template-rows: auto auto 1fr auto;
  height: 100%;
}

.GridViewCol1 {
  /* padding-left: 8px; */
  grid-area: main;
  padding-right: 20px;
}

.GridViewCol2 {
  /* padding-top: 8px;
    padding-left: 8px; */
  grid-area: side;
  align-self: center;
}

.GridViewCol3 {
  display: flex;
  grid-area: isbn;
  /* padding-left: 8px; */
  margin: 10px 0;
  padding-right: 20px;
}

.GridViewCol4 {
  /* padding-left: 8px; */
  grid-area: main2;
  margin: 10px 0;

  padding-right: 20px;
}

.GridViewCol5 {
  display: flex; 
  grid-area: footer;
  justify-content: center;
  margin-top: 10px;
}

.GridViewCol5 button {
  width: 100%;
  margin: 0 5px;
}

.GridViewCol5 button:nth-child(2) {
  width: 30%;
}

.img {
  width: 100%;
  max-height: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.TableCardViewContainer {
  min-width: 200px;
  max-width: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 8px;
  margin-top: 10px;
}

.img_view {
  max-height: 130px;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.button_on_right {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

.button_on_right button {
  margin: 0 5px;
}

.DialogBox_container label {
  font-size: 2vh;
}

.btn {
  width: 30%;
}

.dialogButtonContainer {
  display: flex;
  justify-content: flex-end;
}
.dialogButtonContainer button:last-child {
  color: white;
  background: linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%);
  width: 50px;
  border-radius: 50px;
  height: 25px;
  font-size: 0.7em;
}
.dialogButtonContainer button:first-child {
  color: rgba(181 60 63);
  background: pink;
  width: 50px;
  border-radius: 50px;
  height: 25px;
  font-size: 0.7em;
}
